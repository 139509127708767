<script lang="ts" setup>
import Modal from '../components/Modal.vue';
import { type User } from '../user';
import UserList from './UserList.vue';
import { useProjectStore } from '../store/project';
import { useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';
import { useToastStore } from '../store/toasts';
import Spinner from '../components/Spinner.vue';
import { CompanyRoleProfile } from '../company-role-profile';

const projectStore = useProjectStore();
const router = useRouter();
const joinRoles = ref<CompanyRoleProfile[]>([]);
const isLoadingRoles = ref(false);

defineProps<{
  users: User[];
}>();

const emit = defineEmits<{
  (event: 'joinTeam', role: number | null): void;
}>();

function backToProjects() {
  projectStore.resetProject();
  router.push('/projects');
}

function joinTeam(role: CompanyRoleProfile) {
  if (!role.id) {
    emit('joinTeam', null);
    return;
  }
  emit('joinTeam', role.id);
}

onMounted(async () => {
  if (!projectStore.currentProject) {
    backToProjects();
    throw new Error('UnauthorizedModal: No project selected');
  }
  isLoadingRoles.value = true;
  try {
    joinRoles.value = await projectStore.getJoinRoles(
      projectStore.currentProject
    );
  } catch (e: any) {
    useToastStore().unexpected(e);
    // default to full access
    joinRoles.value = [];
    throw e;
  } finally {
    isLoadingRoles.value = false;
  }
});
</script>

<template>
  <Modal :closable="false" shouldCloseOnShadeClick data-cy="join-team-modal">
    <template #header> Unauthorized User </template>
    <div class="text-center mb-2">
      <h2 class="fal fa-users mb-3"></h2>
      <b class="d-block text-muted">
        You are not currently part of this project's team
      </b>
    </div>

    <div v-if="users.length" class="py-3 text-center">
      <hr class="mb-3" />
      <p class="text-muted fw-medium mb-1">
        Below are a list of the users that are currently a part of this team.
      </p>

      <div class="d-flex align-items-center justify-content-center w-100">
        <UserList :users="users" showAll />
      </div>
    </div>
    <template #footer>
      <h6 class="w-100 text-center m-0 mb-3">Join project's team as a</h6>
      <Spinner v-if="isLoadingRoles" />
      <button
        v-else-if="joinRoles.length === 0"
        class="btn btn-primary btn-lg w-100 py-2"
        @click="$emit('joinTeam', null)"
      >
        Full Access
        <small class="d-block">Full team member access.</small>
      </button>
      <template v-else>
        <button
          v-for="(role, index) in joinRoles"
          class="btn btn-lg w-100 py-3"
          :class="index === 0 ? 'btn-primary' : 'btn-outline-secondary'"
          @click="joinTeam(role)"
        >
          {{ role.title }}
          <small v-if="role.id === null" class="d-block"
            >Full team member access.</small
          >
        </button>
      </template>
      <h6 class="w-100 text-center my-3">or</h6>
      <button class="btn btn-light w-100 btn-lg me-0" @click="backToProjects">
        Back To Projects
      </button>
    </template>
  </Modal>
</template>
