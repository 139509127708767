<script lang="ts" setup>
import { computed } from 'vue';
import moment from 'moment';
import ButtonSpinner from '@component-library/components/ButtonSpinner.vue';
import AdminAppEditor from './AdminAppEditor.vue';
import ChildAppList from './ChildAppList.vue';
import {
  getAppIcon,
  getAppShareGroupTitle,
  getAppShareGroupDescription,
  getAppCreator,
} from '@component-library/business-logic/app';
import { isCompanyManager } from '@component-library/company';
import useAuth from '@component-library/composables/useAuth';
import { App, ShareGroup } from '@component-library/gather';
import AlertBox from '@component-library/components/AlertBox.vue';

const auth = useAuth();
const props = defineProps<{
  shareGroup: ShareGroup;
  currentApps: App[];
  canCreateApp: boolean;
  importing: boolean;
}>();

const hasImportedApp = computed(() => {
  return props.currentApps.some((selectedApp) => {
    return props.shareGroup.app_shareables?.some(
      (appShareable) => selectedApp.id === appShareable.template_tab_id
    );
  });
});

const canEditApp = computed(() => {
  if (
    props.shareGroup.app_shareables?.some(
      (appShareable) => appShareable.app?.custom_template_id
    )
  ) {
    return false;
  }

  const user = auth.user();
  if (user.role === 'admin') {
    return true;
  }
  if (!user.company) {
    return false;
  }
  return (
    (isCompanyManager(user) &&
      props.shareGroup.app_shareables?.some(
        (s) =>
          s.share_group?.shareable_type.includes('Company') &&
          s.share_group?.shareable_id === auth.user().company.id
      )) ||
    props.shareGroup.app_shareables?.some(
      (s) =>
        s.share_group?.shareable_type.includes('User') &&
        s.share_group?.shareable_id === auth.user().user_id
    )
  ); // && user.company.id === props.app;
});

const emit = defineEmits(['importApp', 'getApps']);

function importShareGroup() {
  // Todo rename emit to importShareGroup
  emit('importApp', props.shareGroup.id);
}
</script>

<template>
  <div>
    <h6>{{ getAppShareGroupTitle(shareGroup) }}</h6>

    <img
      v-if="getAppIcon(shareGroup)"
      :src="getAppIcon(shareGroup)"
      class="mb-3"
      style="height: 64px; width: 64px"
    />

    <div v-if="getAppShareGroupDescription(shareGroup)">
      <b class="d-block">Description</b>
      <p>
        {{ getAppShareGroupDescription(shareGroup) }}
      </p>
    </div>
    <div v-if="getAppCreator(shareGroup)">
      <b>Designed By:</b>
      <span class="ms-1"> {{ getAppCreator(shareGroup) }}</span>
    </div>
    <div>
      <b>Last Update:</b>
      <span class="ms-1">{{
        moment.utc(shareGroup.updated_at).local().format('DD-MM-YYYY hh:mm A')
      }}</span>
    </div>

    <ChildAppList title="Apps to import:" :shareGroup="shareGroup" />

    <ButtonSpinner
      v-if="canCreateApp"
      class="btn btn-primary w-100 p-3 fw-bold mt-3"
      :isLoading="importing"
      @click.prevent="importShareGroup"
    >
      <i class="fas fa-plus fa-fw" /> Import into project
    </ButtonSpinner>
    <AlertBox v-else type="warning" class="mt-3">
      You do not have permission to import apps.
    </AlertBox>
    <ButtonSpinner
      v-if="hasImportedApp"
      class="btn w-100 p-3 fw-bold mt-3 btn-outline-danger disabled"
    >
      <i class="fas fa-exclamation-triangle fa-fw" /> This app is already in
      your project.
    </ButtonSpinner>

    <AdminAppEditor
      v-if="canEditApp"
      :shareGroup="shareGroup"
      @getApps="emit('getApps')"
    />
  </div>
</template>
