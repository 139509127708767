// import { useStore } from '@/js/store';
import useApi from '@component-library/api';
import { ScenarioSet } from '@component-library/enviro';
import { captureException } from '@component-library/sentry';
import {
  checkIsFeatureServerFolderLayerModel,
  checkIsImageServerFolderLayerModel,
  checkIsMapServerFolderLayerModel,
  requestFeatureServerLayerRenderer,
  requestImageServerLayerRenderer,
  requestMapServerLayerRenderers,
} from '@maps/lib/olbm';
import { withLoadManager } from '@maps/lib/olbm/common/LoadManager';
import type { MarkerIdentifier } from '@maps/lib/olbm/layer/sample/types';
import { normalizeSampleLocation } from '@maps/lib/olbm/layer/sample/utils';
import type { Integration } from '@maps/lib/olbm/layer/service/integration';
import { IntegrationId } from '@maps/lib/olbm/layer/service/integration';
import type { ShapeProperties } from '@maps/lib/olbm/layer/shape/types';
import type { Geojson } from '@maps/lib/olbm/layer/types';
import type {
  AppStylingRule,
  Chemical,
  ChemicalResult,
  Exceedance,
  Extent,
  Figure,
  FigureStylingRule,
  Id,
  LayerModel,
  Pid,
  Sample,
  SampleScopeStatistic,
  Scenario,
  ScenarioStyle,
  SubFolder,
} from '@maps/lib/olbm/types';
import { LayerType } from '@maps/lib/olbm/types';
import type { AxiosStatic } from 'axios';
import { useStore } from '../store';
import useFetchMapsApi from './useFetchMapsApi';

declare const axios: AxiosStatic;

export type LoadLayerModelsResponse = {
  layerModels: LayerModel[];
  lopSampleByLayerId: Record<Id, Sample>;
};

export type LoadSamplesResponse = Sample[];

export type LoadLinkableSamplesResponse = {
  pagination: {
    current_page: number;
    last_page: number;
    data: Sample[];
  };
  allLinkedItemsCount: number;
};

export type LoadSampleResponse = Sample | undefined;

export type LoadFiguresResponse = {
  figures: Figure[];
  figureStylingRules: FigureStylingRule[];
  appStylingRules: AppStylingRule[];
  lastFigureId: number | null;
};

export type LoadScenariosResponse = {
  scenarios: Scenario[];
  scenarioStyles: ScenarioStyle[];
  scenarioSet: ScenarioSet;
};

export type LoadChemicalsResponse = {
  chemicals: Chemical[];
};

export type LoadExceedancesResponse = {
  exceedances: Exceedance[];
};

export type LoadChemicalResultsResponse = {
  results: ChemicalResult[];
};

export type LoadSubFoldersResponse = SubFolder[];

export type LoadSampleScopeStatisticsResponse = SampleScopeStatistic[];

export type LoadIntegrationsResponse = Integration[];

export default function useMapsApi(projectId: number | null = null) {
  const store = useStore();

  if (!projectId) {
    const project = store.state.project as any;
    projectId = project.project_id;
  }

  const fetchMapsApi = useFetchMapsApi(projectId!);

  const api = useApi();

  async function normalizeRawLayerModel(rawLayerModel) {
    if (typeof rawLayerModel.geojson === 'string') {
      rawLayerModel.geojson = JSON.parse(rawLayerModel.geojson);
    }

    const { properties } = rawLayerModel.geojson;
    if (properties.type === LayerType.IMAGE) {
      const { transparency } = properties.image_data;
      if (typeof transparency === 'string') {
        properties.image_data.transparency = parseFloat(transparency);
      }
    }

    if (typeof rawLayerModel.hidden_sub_folders === 'string') {
      rawLayerModel.hidden_sub_folders = JSON.parse(
        rawLayerModel.hidden_sub_folders
      );
    }
    rawLayerModel.hidden_sub_folders = rawLayerModel.hidden_sub_folders ?? [];

    rawLayerModel.visible = rawLayerModel.visible ?? false;
    rawLayerModel.parent_id = rawLayerModel.parent_id ?? undefined;

    // Newly created layers don't have children.
    for (let i = 0; i < rawLayerModel.children?.length; i++) {
      const child = rawLayerModel.children[i];
      await normalizeRawLayerModel(child);
    }

    if (checkIsFeatureServerFolderLayerModel(rawLayerModel)) {
      try {
        for (let i = 0; i < rawLayerModel.children.length; i++) {
          const itemLayerModel = rawLayerModel.children[i];
          const { title, renderer } = await requestFeatureServerLayerRenderer(
            rawLayerModel,
            itemLayerModel
          );
          itemLayerModel.geojson.properties.renderer = renderer;
          if (!itemLayerModel.geojson.properties.title) {
            itemLayerModel.geojson.properties.title = title;
          }
        }
      } catch (e) {
        console.error(e);
        captureException(e);
      }
    }

    if (checkIsMapServerFolderLayerModel(rawLayerModel)) {
      try {
        const results = await requestMapServerLayerRenderers(rawLayerModel);
        for (let i = 0; i < rawLayerModel.children.length; i++) {
          const itemLayerModel = rawLayerModel.children[i];
          const result = results.find(
            (result) => result.id === itemLayerModel.id
          );
          itemLayerModel.geojson.properties.renderer = result?.renderer;
          if (!itemLayerModel.geojson.properties.title) {
            itemLayerModel.geojson.properties.title = result?.title;
          }
        }
      } catch (e) {
        console.error(e);
        captureException(e);
      }
    }

    if (checkIsImageServerFolderLayerModel(rawLayerModel)) {
      try {
        for (let i = 0; i < rawLayerModel.children.length; i++) {
          const itemLayerModel = rawLayerModel.children[i];
          const { title, renderer } = await requestImageServerLayerRenderer(
            rawLayerModel,
            itemLayerModel
          );
          itemLayerModel.geojson.properties.renderer = renderer;
          if (!itemLayerModel.geojson.properties.title) {
            itemLayerModel.geojson.properties.title = title;
          }
        }
      } catch (e) {
        console.error(e);
        captureException(e);
      }
    }
  }

  const ensureGatherFigure = withLoadManager<undefined, []>(async () => {
    await axios.post('/api/project/ensure-gather-figure');
  });

  const ensureSampleGroups = withLoadManager<undefined, []>(async () => {
    await axios.post(`/api/project/ensure-sample-groups`);
  });

  const loadFiguresRaw = withLoadManager<any, []>(async () => {
    return await fetchMapsApi.loadFiguresRaw();
  });

  const loadFigures = withLoadManager<LoadFiguresResponse, []>(async () => {
    const {
      figures,
      figure_styling_rules: figureStylingRules,
      template_tab_styling_rules: appStylingRules,
      last_figure_id: lastFigureId,
    } = await loadFiguresRaw();

    return {
      figures: figures.map((figure) => ({
        ...figure,
        gather_access: figure.gather_access === 1 ? true : false,
        is_basemap: figure.is_basemap,
      })),
      figureStylingRules,
      appStylingRules,
      lastFigureId,
    };
  });

  const loadLayerModelsRaw = withLoadManager<any, [number]>(async (figureId) => {
    return await fetchMapsApi.loadLayerModelsRaw(figureId);
  });

  const loadLayerModels = withLoadManager<LoadLayerModelsResponse, [number]>(
    async (figureId) => {
      const {
        layers: rawLayerModels,
        lop_sample_by_layer_id: lopSampleByLayerId,
      } = await loadLayerModelsRaw(figureId);

      for (let i = 0; i < rawLayerModels.length; i++) {
        await normalizeRawLayerModel(rawLayerModels[i]);
      }

      Object.keys(lopSampleByLayerId).forEach((layerModelId) => {
        const lopSample = lopSampleByLayerId[layerModelId];
        const { geojson } = lopSample.area_figure_layer;
        if (typeof geojson === 'string') {
          lopSample.area_figure_layer.geojson = JSON.parse(geojson);
        }
      });

      return { layerModels: rawLayerModels, lopSampleByLayerId };
    }
  );

  const modifyLayerOrdering = withLoadManager<[], any[]>(
    // TODO: layersForOrdering Needs a type @Matt
    async (layersForOrdering: any[]) => {
      return await api.post('/figure8/layer/modify-ordering', {
        project_id: projectId,
        layers: layersForOrdering,
      });
    }
  );

  const loadSamples = withLoadManager<LoadSamplesResponse, [Id, Extent]>(
    async (sampleGroupId: Id, extentInWgs84: Extent) => {
      if (extentInWgs84.some((coord) => isNaN(coord))) {
        console.error('Invalid extent', extentInWgs84);
        extentInWgs84 = [-180, -90, 180, 90];
      }
      const { data: samples } = await axios.get(
        '/api/project/sample/get-scoped',
        {
          params: {
            sample_group_id: sampleGroupId,
            extent: extentInWgs84.join(','),
          },
        }
      );

      return (samples || []).map(normalizeSampleLocation);
    }
  );

  const loadSample = withLoadManager<LoadSampleResponse, [Pid]>(async (id: Pid) => {
    const { data: sample } = await axios.get(`/api/project/sample/get/${id}`);
    return sample ? normalizeSampleLocation(sample) : undefined;
  });

  // Linkable samples include linked samples and samples that have potential to be linked.
  const loadLinkableSamples = withLoadManager<LoadLinkableSamplesResponse, [Pid | null, Pid, number]>(
    async (sampleId: Pid | null, linkFieldId: Pid, page: number) => {
      const params: { name: string; value: number | null }[] = [
        { name: 'sampleId', value: sampleId },
        { name: 'link_field_id', value: linkFieldId },
        { name: 'page', value: page },
      ];
      const query = params
        .reduce((accu, param) => {
          const { name, value } = param;
          if (value !== null) {
            accu.push(`${name}=${value}`);
          }
          return accu;
        }, [] as string[])
        .join('&');
      const {
        data: { pagination, allLinkedItemsCount },
      } = await axios.get(`/api/project/sample/get-linkable?${query}`);
      pagination.data = pagination.data.map(normalizeSampleLocation);

      return { pagination, allLinkedItemsCount };
    }
  );

  const loadScenariosRaw = withLoadManager<any, []>(async () => {
    return await fetchMapsApi.loadScenariosRaw();
  });

  const loadScenarios = withLoadManager<LoadScenariosResponse, []>(async () => {
    const {
      scenarios,
      styles: scenarioStyles,
      scenario_set: scenarioSet,
    } = await loadScenariosRaw();

    return {
      scenarios,
      scenarioStyles,
      scenarioSet,
    };
  });

  const loadChemicals = withLoadManager<LoadChemicalsResponse, []>(async () => {
    return await fetchMapsApi.loadChemicals();
  });

  const loadExceedances = withLoadManager<LoadExceedancesResponse, []>(async () => {
    return await fetchMapsApi.loadExceedances();
  });

  const loadChemicalResultsRaw = withLoadManager<any, [number[]]>(
    async (chemicalIds: number[]) => {
      return await fetchMapsApi.loadChemicalResultsRaw(chemicalIds);
    }
  );

  const loadChemicalResults = withLoadManager<LoadChemicalResultsResponse, [number[]]>(
    async (chemicalIds: number[]) => {
      const { chemicals: results } = await loadChemicalResultsRaw(chemicalIds);
      return {
        results,
      };
    }
  );

  const updateLayerModelVisibility = withLoadManager<undefined, [Id, Id, boolean]>(
    async (figureId: Id, layerModelId: Id, isVisible: boolean) => {
      await axios.post('/api/project/layer/toggle', {
        figure_id: figureId,
        layer_id: layerModelId,
        visible: isVisible,
      });
    }
  );

  const loadSubFolders = withLoadManager<LoadSubFoldersResponse, []>(async () => {
    return await fetchMapsApi.loadSubFolders();
  });

  const loadSampleScopeStatistics =
    withLoadManager<LoadSampleScopeStatisticsResponse, []>(async () => {
      const { data } = await api.get('/figure8/layer/sample-scope-statistics', {
        params: { project_id: projectId },
      });
      return data;
    });

  const loadIntegrations = withLoadManager<LoadIntegrationsResponse, [IntegrationId[]]>(
    async (ids: IntegrationId[]) => {
      const result: Integration[] = [];

      for (const id of ids) {
        try {
          const {
            data: {
              data: {
                integration: { access_token: apiKey },
              },
            },
          } = await api.get(`/company/external-service/${id}`);
          result.push({ id, apiKey: apiKey ?? null });
        } catch (e) {
          result.push({ id, apiKey: null });
          console.warn(e);
        }
      }

      return result;
    }
  );

  const saveLayerModel = withLoadManager<void, [Id, LayerModel]>(
    async (figureId: Id, layerModel: LayerModel) => {
      await api.post('/figure8/layer/modify', {
        project_id: projectId,
        figure_id: figureId,
        layer_id: layerModel.id,
        layer: layerModel.geojson,
      });
    }
  );

  const updateSubFolderVisibility = withLoadManager<undefined, [Id, Id, string[]]>(
    async (figureId: Id, layerModelId: Id, hidden_sub_folders: string[]) => {
      await api.post('/figure8/figure/layer/modify', {
        project_id: projectId,
        figure_id: figureId,
        layer_id: layerModelId,
        key: 'hidden_sub_folders',
        value: hidden_sub_folders,
      });
    }
  );

  const savePlainShape = withLoadManager<any, [number, number | undefined, Geojson<ShapeProperties>]>(
    async (
      figureId: number,
      layerModelId: number | undefined, // Create the plain layer if the layerModelId is undefined.
      geojson: Geojson<ShapeProperties>
    ) => {
      const {
        data: { layer },
      } = await api.post('/figure8/layer/modify', {
        project_id: projectId,
        layer_id: layerModelId,
        figure_id: figureId,
        layer: geojson,
      });

      await normalizeRawLayerModel(layer);
      return layer;
    }
  );

  const deletePlainShape = withLoadManager<undefined, [number]>(
    async (layerModelId: number) => {
      await api.delete('/figure8/layer/delete', {
        params: { project_id: projectId, id: layerModelId },
      });
    }
  );

  const modifyFigure = withLoadManager<undefined, [Pid, any]>(
    // @Matt lockedLayerModelIds needs type
    async (figureId: Pid, lockedLayerModelIds: any) => {
      await axios.put(`/api/project/figure/${figureId}`, {
        locked_layer_ids: lockedLayerModelIds,
      });
    }
  );

  const saveSelectedBasemapId = withLoadManager<void, [Pid, number]>(
    async (figureId: Pid, selectedBasemapId: number) => {
      await api.post('/figure8/figure/modify-column', {
        project_id: projectId,
        id: figureId,
        basemap_index: selectedBasemapId,
      });
    }
  );

  const generateMarkerIdentifier = withLoadManager<
    Exclude<MarkerIdentifier, string>,
    []
  >(async () => {
    const { data: markerIdentifier } = await api.post(
      'figure8/layer/marker-identifier/generate',
      {
        project_id: projectId,
      }
    );
    return markerIdentifier;
  });

  return {
    ensureGatherFigure,
    ensureSampleGroups,
    loadFigures,
    loadFiguresRaw,
    loadLayerModels,
    loadLayerModelsRaw,
    modifyLayerOrdering,
    loadSamples,
    loadSample,
    loadLinkableSamples,
    loadScenarios,
    loadScenariosRaw,
    loadChemicals,
    loadExceedances,
    loadChemicalResults,
    loadChemicalResultsRaw,
    updateLayerModelVisibility,
    loadSubFolders,
    saveLayerModel,
    updateSubFolderVisibility,
    loadSampleScopeStatistics,
    loadIntegrations,
    savePlainShape,
    deletePlainShape,
    modifyFigure,
    saveSelectedBasemapId,
    generateMarkerIdentifier,
  };
}
