<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import axios from 'axios';
import Breadcrumb from '@/js/components/Breadcrumb.vue';
import Paginate from '@/js/components/Pagination.vue';
import ButtonSpinner from '@component-library/components/ButtonSpinner.vue';
import { captureException } from '@component-library/sentry';
import AppList from './components/AppList.vue';
import AppListFilter from './components/AppListFilter.vue';
import AppManager from './components/AppManager.vue';
import { useRoute } from 'vue-router';
import type { App, ShareGroup } from '@component-library/gather';
import { useToastStore } from '@component-library/store/toasts';
import { DATANEST_URL } from '@component-library/env';
import useViewRestriction from '@component-library/composables/useViewRestriction';
import { AVAILABLE_PERMISSIONS } from '@component-library/company-role-profile';
import Spinner from '@component-library/components/Spinner.vue';
import CurrentAppList from './components/CurrentAppList.vue';

const route = useRoute();
const toastStore = useToastStore();

const loadingSelectedApps = ref(false),
  currentApps = ref<App[]>([]),
  loadingApps = ref(false),
  shareGroups = ref<{
    data: ShareGroup[];
    current_page: number;
  } | null>(null),
  appType = ref('all'),
  selectedShareGroup = ref<ShareGroup | null>(null),
  importing = ref(false),
  orderBy = ref('recent');

const isCreatingApp = ref(false);

const isLoadingApps = computed(() => {
  return !shareGroups.value && loadingApps.value && appType.value == 'all';
});

const { isViewOnly, hasPermissionToAccess } = useViewRestriction(
  AVAILABLE_PERMISSIONS.GATHER_APP_EDITOR
);
const canCreateApp = computed(() => {
  return !isViewOnly() && hasPermissionToAccess();
});

onMounted(() => {
  getSelectedApps();
  loadShareGroups();
});
async function loadShareGroups(type = null, page = 1) {
  if (loadingApps.value) {
    return;
  }

  loadingApps.value = true;

  if (type) {
    appType.value = type;
  }

  try {
    const { data } = await axios.get('/api/template/shared-apps', {
      params: {
        app_type: appType.value,
        page: page,
        order_by: orderBy.value,
      },
    });
    shareGroups.value = data;
  } catch (e) {
    toastStore.unexpected();
    throw e;
  } finally {
    loadingApps.value = false;
  }
}

async function getSelectedApps() {
  loadingSelectedApps.value = true;

  try {
    const { data } = await axios.get(
      `/api/template/${route.params.project_id}/apps`
    );

    currentApps.value = data.templates;
  } catch (e) {
    toastStore.unexpected();
    throw e;
  } finally {
    loadingSelectedApps.value = false;
  }
}

function handlePaginate(page) {
  loadShareGroups(null, page);
}

async function createApp() {
  isCreatingApp.value = true;

  try {
    await axios.post(`/api/template/${route.params.project_id}/update`, {
      template_tabs: [
        {
          title: 'Untitled App',
          is_locked: false,
          prefix: null,
          drawing_type: 'point',
          drawing_colour: null,
          point_icon: 0,
          drawing_properties: null,
        },
      ],
    });
    await getSelectedApps();
  } catch (e) {
    toastStore.unexpected();
    captureException(e);
  } finally {
    isCreatingApp.value = false;
  }
}

async function importApp(share_group_id) {
  importing.value = true;

  try {
    await axios.post(`/api/template/${route.params.project_id}/import-apps`, {
      share_group_id,
    });

    if (selectedShareGroup.value) {
      currentApps.value.concat(selectedShareGroup.value.apps ?? []);
      selectedShareGroup.value = null;
    }

    await getSelectedApps();
  } catch (e) {
    toastStore.unexpected();
    throw e;
  } finally {
    importing.value = false;
  }
}

async function selectApp(shareGroup: ShareGroup) {
  selectedShareGroup.value = shareGroup;
}

function openGatherSupportPage() {
  window.open(DATANEST_URL + '/support/gather', '_blank');
}
</script>

<template>
  <div
    class="template-page-content col-12 col-md-10 offset-0 offset-md-1 py-4 px-3 px-md-0 h-100 d-flex flex-column"
  >
    <div
      v-if="isLoadingApps"
      class="d-flex align-items-center justify-content-center flex-column text-center w-100 h-100 flex-fill"
    >
      <Spinner large />
      <small class="text-muted mt-2 d-block"> Loading apps... </small>
    </div>
    <template v-else>
      <Breadcrumb :routes="['template_editor', 'app_manager']" class="mb-4" />

      <div class="row flex-fill">
        <div class="col-md-5 col-lg-3 mb-3">
          <div class="sticky-container">
            <div
              class="section-header p-3 d-flex align-items-center justify-content-between bg-light mb-3"
            >
              <h6 class="mb-0">Project Apps</h6>
              <ButtonSpinner
                v-if="canCreateApp"
                class="btn btn-primary btn-sm"
                :isLoading="isCreatingApp"
                @click.prevent="createApp"
              >
                <i class="fal fa-plus fa-fw" />
                <span>Create App</span>
              </ButtonSpinner>
            </div>

            <div v-if="currentApps.length == 0">
              <h6>You have not selected any apps yet.</h6>
              <p class="text-muted mb-0">
                It's very easy to get started, simply select an app you'd like
                to add to your project.
              </p>
            </div>
            <CurrentAppList v-else :currentApps="currentApps" />
          </div>
        </div>
        <div
          class="col-md-7 col-lg-6 d-flex flex-column"
          :class="{
            'app-selected': selectedShareGroup,
          }"
        >
          <AppListFilter
            :shareGroups="shareGroups?.data ?? []"
            :type="appType"
            @loadShareGroups="loadShareGroups"
          />
          <div
            v-if="shareGroups && shareGroups.data.length > 0"
            class="d-flex align-items-center justify-content-between mb-3"
          >
            <div>
              <Paginate
                v-if="shareGroups"
                :pagination="shareGroups"
                :offset="12"
                @paginate="handlePaginate"
              />
            </div>

            <select
              v-model="orderBy"
              class="form-select form-select-sm"
              style="width: 200px"
              @change="loadShareGroups()"
            >
              <option value="recent">Recently Shared</option>
              <option value="alphabetical">Alphabetical</option>
            </select>
          </div>

          <div
            v-if="loadingApps"
            class="text-center d-flex align-items-center justify-content-center flex-fill"
          >
            <Spinner large />
          </div>
          <AppList
            v-else
            :shareGroups="shareGroups?.data ?? []"
            :currentApps="currentApps"
            :selectedShareGroup="selectedShareGroup"
            @selectApp="selectApp"
          />
        </div>
        <div class="col-md-5 col-lg-3">
          <div class="sticky-container mb-3">
            <template v-if="selectedShareGroup">
              <div
                class="section-header p-3 d-flex align-items-center justify-content-between bg-light mb-3"
              >
                <h6 class="mb-0">Manager</h6>
                <i
                  class="fas fa-times clickable"
                  @click="() => (selectedShareGroup = null)"
                />
              </div>

              <AppManager
                v-if="selectedShareGroup"
                :shareGroup="selectedShareGroup"
                :canCreateApp="canCreateApp"
                :currentApps="currentApps"
                :importing="importing"
                @importApp="importApp"
                @getApps="loadShareGroups"
              />

              <hr />
            </template>

            <button
              type="button"
              class="btn btn-light w-100 p-3 fw-bold"
              @click="openGatherSupportPage"
            >
              Learn more about Gather Apps?
            </button>
            <router-link
              :to="`/${route.params.project_id}/map`"
              class="btn btn-light w-100 p-3 fw-bold mt-2"
            >
              Go to Collection
            </router-link>
            <router-link
              v-if="currentApps.length > 0"
              :to="`/template/${route.params.project_id}/editor`"
              class="btn btn-primary w-100 p-3 fw-bold mt-2"
            >
              Go to App Editor
            </router-link>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped>
.sticky-container {
  position: sticky;
  top: 64px;
}

@media only screen and (max-width: 768px) {
  .app-selected {
    display: none;
  }
}
</style>
