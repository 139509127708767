<script lang="ts" setup>
import { onMounted, computed, onBeforeUnmount, ref } from 'vue';
import AppGrid from './AppGrid.vue';
import { useTaskStore } from '../store/tasks';
import EventBus from '../EventBus';
import { useProjectStore } from '../store/project';
import AppSwitcherModal from './AppModal.vue';
import { goToApp as _goToApp } from './handover';
import { Project } from '../project';

const taskStore = useTaskStore();
const projectStore = useProjectStore();

const shortCuts = {
  p: ['hub', 'project_pathway'],
  n: ['hub', 'project_create'],
  m: ['hub', 'map'],
  f: ['hub', 'map'],
  d: ['hub', 'project_dashboard'],
  e: ['gather', 'template_editor'],
  a: ['gather', 'template_editor'],
  g: ['gather', 'project_map'],
  c: ['gather', 'project_map'],
  h: [null, 'https://app.datanest.earth/support/gather'],
};

defineProps<{
  rightSide?: boolean;
}>();

const emit = defineEmits(['loadProjectData']);

function toggleDropdown() {
  if (projectStore.isFullPagePopup) {
    return;
  }

  projectStore.showSwitcherDropdown = !projectStore.showSwitcherDropdown;

  if (projectStore.showSwitcherDropdown) {
    taskStore.completeTask('open-where-to-next');

    emit('loadProjectData');
  }
}

const isDropdown = computed(() => {
  if (typeof window !== 'undefined') {
    return window.innerWidth > 768;
  }
  return true;
});

function closeDropdown() {
  projectStore.showSwitcherDropdown = false;
  projectStore.isFullPagePopup = false;
}

function clickOutside() {
  if (projectStore.isFullPagePopup || !isDropdown.value) {
    return;
  }
  closeDropdown();
}

function goToApp({
  route,
  app,
  isMiddleClick = false,
  project,
}: {
  route: string;
  app: string;
  isMiddleClick: boolean;
  $router?: never;
  $store?: never;
  project?: Project;
}) {
  closeDropdown();

  _goToApp({
    route,
    app,
    isMiddleClick,
    project: project ?? projectStore.currentProject ?? null,
  });
}

onBeforeUnmount(() => {
  EventBus.$off('goToApp', goToApp);
});

onMounted(() => {
  EventBus.$on('goToApp', goToApp);

  window.addEventListener('keyup', (e) => {
    if (e.key == 'Escape' && projectStore.showSwitcherDropdown) {
      projectStore.isFullPagePopup = false;
      projectStore.showSwitcherDropdown = false;
    } else if (
      document.activeElement &&
      (['INPUT', 'SELECT', 'TEXTAREA'].includes(
        document.activeElement.tagName.toUpperCase()
      ) ||
        (document.activeElement as any).isContentEditable)
    ) {
      return;
    } else if (!projectStore.showSwitcherDropdown) {
      return;
    }

    for (let shortCut of Object.keys(shortCuts)) {
      if (e.key.toLowerCase() == shortCut.toLowerCase()) {
        if (!shortCuts[shortCut][0]) {
          window.open(shortCuts[shortCut][1]);
          projectStore.showSwitcherDropdown = false;
          return;
        }
        goToApp({
          route: shortCuts[shortCut][1],
          app: shortCuts[shortCut][0],
          isMiddleClick: e.key == shortCut.toUpperCase(),
        });
      }
    }
  });
});
</script>

<template>
  <div>
    <div
      v-if="projectStore.showSwitcherDropdown && projectStore.isFullPagePopup"
      style="
        position: fixed;
        z-index: 10010 !important;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        transition: opacity 0.3s ease;
        display: block;
        overflow: hidden;
      "
    />

    <div
      v-if="projectStore.currentProject"
      v-click-outside="clickOutside"
      class="dropdown full-page"
      style="z-index: 99999 !important"
    >
      <div
        class="btn btn-header"
        data-cy="app-switcher-dropdown-btn"
        @click="toggleDropdown"
      >
        <i class="fas fa-th me-0"></i>
      </div>

      <Transition name="dropdown">
        <div
          v-show="projectStore.showSwitcherDropdown && isDropdown"
          class="dropdown-menu p-0 mt-2"
          style="
            width: 340px;
            z-index: 99999 !important;
            height: auto;
            max-height: calc(100vh - 60px);
            overflow-y: auto;
          "
          :class="{
            show: projectStore.showSwitcherDropdown,
            right: rightSide,
          }"
        >
          <div class="p-3" style="padding-bottom: 0.5rem !important">
            <h6>Where to next?</h6>
          </div>

          <AppGrid
            ref="appGrid"
            class="p-3"
            style="padding-top: 0em !important"
            @closeDropdown="closeDropdown"
          />
        </div>
      </Transition>
    </div>

    <AppSwitcherModal
      v-if="projectStore.showSwitcherDropdown && !isDropdown"
      @close="closeDropdown"
    />
  </div>
</template>

<style scoped>
@media all and (max-width: 992px) {
  .dropdown-menu.right {
    right: 0;
  }
}
</style>
