import {
  LoadChemicalsResponse,
  LoadExceedancesResponse,
  LoadSubFoldersResponse,
} from './useMapsApi';
import { getJSON as getJSONByFetch } from '../helpers/fetch-api';
import axios from 'axios';
import { DATANEST_URL } from '@component-library/env';

function getBaseUrl() {
  return `${DATANEST_URL}/api`;
}

export default function useFetchMapsApi(projectId: number, useAxios = true) {
  const getJSON = async (
    url: string,
    {
      params = {},
      headers = {},
    }: {
      params?: Record<string, any>;
      headers?: Record<string, any>;
    } = {}
  ) => {
    if (useAxios) {
      return await axios.get(url, { params });
    } else {
      return await getJSONByFetch(url, { params, headers });
    }
  };

  const loadFiguresRaw = async (): Promise<any> => {
    const {
      data: {
        figures,
        figure_styling_rules,
        template_tab_styling_rules,
        last_figure_id,
      },
    } = await getJSON(`${getBaseUrl()}/figure8/figure/list`, {
      params: { project_id: projectId },
    });

    return {
      figures,
      figure_styling_rules,
      template_tab_styling_rules,
      last_figure_id,
    };
  };

  const loadLayerModelsRaw = async (figureId: number): Promise<any> => {
    const {
      data: { layers, lop_sample_by_layer_id: lopSampleByLayerId },
    } = await getJSON(`${getBaseUrl()}/figure8/layer/list`, {
      params: { project_id: projectId },
    });
    const {
      data: {
        layers: figureLayers,
        lop_sample_by_layer_id: figureLopSampleByLayerId,
      },
    } = await getJSON(`${getBaseUrl()}/figure8/figure-layer/list`, {
      params: { project_id: projectId, figure_id: figureId },
    });
    const mergedLayers = layers.map((layer) => {
      const figureLayer = figureLayers.find((fl) => fl.id === layer.id);
      return {
        ...layer,
        ...figureLayer,
      };
    });
    const mergedLopSampleByLayerId = Object.keys(lopSampleByLayerId).reduce(
      (accu, layerId) => {
        const lopSample = lopSampleByLayerId[layerId];
        const figureLopSample = figureLopSampleByLayerId[layerId];
        accu[layerId] = {
          ...lopSample,
          ...figureLopSample,
        };
        return accu;
      },
      {}
    );

    return {
      layers: mergedLayers,
      lop_sample_by_layer_id: mergedLopSampleByLayerId,
    };
  };

  const loadSubFolders = async (): Promise<LoadSubFoldersResponse> => {
    const { data } = await getJSON(
      `${getBaseUrl()}/figure8/figure/sub-folders`,
      {
        params: { project_id: projectId },
      }
    );

    return data;
  };

  const loadScenariosRaw = async (): Promise<any> => {
    const {
      data: { scenarios, styles, scenario_set },
    } = await getJSON(`${getBaseUrl()}/dashboard/scenario/get-styling`, {
      params: { project_id: projectId },
    });

    return {
      scenarios,
      styles,
      scenario_set,
    };
  };

  const loadChemicals = async (): Promise<LoadChemicalsResponse> => {
    const {
      data: { chemicals },
    } = await getJSON(`${getBaseUrl()}/figure8/data/chemicals`, {
      params: { project_id: projectId },
    });

    return {
      chemicals,
    };
  };

  const loadExceedances = async (): Promise<LoadExceedancesResponse> => {
    const {
      data: { exceedances },
    } = await getJSON(`${getBaseUrl()}/figure8/data/exceedances`, {
      params: { project_id: projectId },
    });

    return {
      exceedances,
    };
  };

  const loadChemicalResultsRaw = async (
    chemicalIds: number[]
  ): Promise<any> => {
    const {
      data: { chemicals },
    } = await getJSON(`${getBaseUrl()}/figure8/data/chemical-results`, {
      params: {
        project_id: projectId,
        chemical_ids: chemicalIds,
      },
    });

    return {
      chemicals,
    };
  };

  return {
    loadFiguresRaw,
    loadLayerModelsRaw,
    loadScenariosRaw,
    loadChemicals,
    loadExceedances,
    loadChemicalResultsRaw,
    loadSubFolders,
  };
}
