<script lang="ts" setup>
import {
  getAppIcon,
  getAppShareGroupTitle,
} from '@component-library/business-logic/app';
import { App } from '@component-library/gather';
import { useRouter } from 'vue-router';

const router = useRouter();

defineProps<{
  currentApps: App[];
}>();
</script>

<template>
  <div class="list-group">
    <div
      v-for="app in currentApps"
      class="list-group-item list-group-item-action d-flex align-items-center"
    >
      <div class="d-flex align-items-center justify-content-between w-100">
        <div>
          <img
            v-if="getAppIcon(app)"
            :src="getAppIcon(app)"
            class="me-1"
            style="width: 32px; height: 32px"
          />
          {{ getAppShareGroupTitle(app) }}
        </div>
        <button
          class="btn btn-sm btn-primary"
          @click="
            router.push('/template/' + app.project_id + '/editor/' + app.id)
          "
        >
          <i class="fas fa-arrow-left" />
        </button>
      </div>
    </div>
  </div>
</template>
