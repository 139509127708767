<script lang="ts" setup>
import { ref, onBeforeUnmount, onMounted } from 'vue';
import { DATANEST_URL } from '../env';
import { openNewTab } from '../utils';
import Spinner from './Spinner.vue';

const props = withDefaults(
  defineProps<{
    full?: boolean;
    half?: boolean;
    threeQuarter?: boolean;
    threeFifth?: boolean;
    twoThird?: boolean;
    fullHeight?: boolean;
    noOverflow?: boolean;
    small?: boolean;
    loading?: boolean;
    closable?: boolean;
    hideHeader?: boolean;
    isOverflowHidden?: boolean;
    stretch?: boolean;
    shouldCloseOnShadeClick?: boolean;
    title?: string;
    supportTutorialId?: number;
    dataCy?: string;
  }>(),
  {
    closable: true,
    shouldCloseOnShadeClick: true,
  }
);

const emit = defineEmits<{
  (event: 'show'): void;
  (event: 'close'): void;
  (event: 'modalBodyScroll', scroll: { top: number; left: number }): void;
}>();

const isVisible = ref(false);

function close() {
  emit('close');
}

function handleShadeClick() {
  if (!props.closable || !props.shouldCloseOnShadeClick) {
    return;
  }

  close();
}

function keydown(e: KeyboardEvent) {
  if (e.key === 'Escape') {
    close();
  }
}

function handleSupportTutorialOpen() {
  const url = `${DATANEST_URL}/support/tutorial/${props.supportTutorialId}`;
  openNewTab(url);
}

function handleModalBodyScroll(event: UIEvent) {
  const { target } = event;

  let scroll;
  if (!target) {
    scroll = { top: 0, left: 0 };
  } else {
    const modalBoyEl = target as HTMLDivElement;
    const { scrollTop: top, scrollLeft: left } = modalBoyEl;
    scroll = { top, left };
  }

  emit('modalBodyScroll', scroll);
}

onMounted(() => {
  document.addEventListener('keydown', keydown);

  setTimeout(() => {
    isVisible.value = true;
    emit('show');
  }, 100);
});

onBeforeUnmount(() => {
  document.removeEventListener('keydown', keydown);
});
</script>

<template>
  <div
    :class="[
      'modal modal-mask fade',
      {
        show: isVisible,
        'no-overflow': noOverflow,
      },
    ]"
    :data-cy="dataCy"
    @mousedown="handleShadeClick"
  >
    <div
      :class="[
        'modal-dialog modal-dialog-centered modal-dialog-scrollable',
        {
          'full-modal': full,
          'modal-lg': half,
          'modal-sm': small,
          'full-height': stretch,
          'modal-three-quarter': threeQuarter,
          'modal-two-third': twoThird,
          'modal-three-fifth': threeFifth,
        },
      ]"
    >
      <div
        :class="['modal-content', loading ? 'flex justify-content-center' : '']"
        @mousedown.stop
      >
        <div v-if="loading" class="text-center py-4">
          <Spinner large />
        </div>
        <template v-else>
          <div
            v-if="closable && !hideHeader"
            class="modal-header d-flex align-items-center"
          >
            <div
              v-if="$slots['header'] || title"
              class="modal-title flex-grow-1"
            >
              {{ title }}
              <slot name="header" />
            </div>

            <div class="d-flex align-items-center">
              <div
                v-if="supportTutorialId"
                class="me-2 help clickable"
                @click="handleSupportTutorialOpen"
              >
                <i class="fas fa-question-circle"></i>
              </div>
              <div class="close clickable" data-cy="close" @click="close">
                <i class="fal fa-times p-1"></i>
              </div>
            </div>
          </div>
          <div
            v-if="$slots.default"
            :class="[
              'modal-body d-flex flex-column',
              {
                'custom-scrollbar': fullHeight,
                'overflow-hidden': isOverflowHidden,
              },
            ]"
            :style="fullHeight ? 'height: 80vh;' : ''"
            @scroll="handleModalBodyScroll"
          >
            <slot name="default" />
          </div>
          <div v-if="$slots['footer']" class="modal-footer">
            <slot name="footer" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
