import './bootstrap';
import { createApp } from 'vue';
import { captureException } from '@sentry/browser';
import ClickOutside from '@component-library/directives/click-outside';
import router from '@/js/routes';
import store from '@/js/store';
import { createPinia } from 'pinia';
import VTooltip from 'v-tooltip';
import App from '@/js/layouts/App.vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import hosts from '@maps/lib/olbm/layer/basemap/config/hosts';
import '@component-library/EventBus';
import createSentry from '@component-library/sentry';
import ToastsStorePlugin from '@component-library/store/plugins/toasts-vue-adapter-plugin';
import { initializeAnalytics } from '@component-library/analytics';
import { useOfflineStorageManagerStore } from '@component-library/store/offline-storage-manager';
import { DATANEST_URL } from '@component-library/env';
//Register offline axios interceptors
import './offline-interceptor';
import { useToastStore } from '@component-library/store/toasts';
import auth from '@component-library/auth';
import { setRouter as setAppSwitcherRouter } from '@component-library/switcher/handover';
import { configureCompat } from '@vue/compat';
import { parseCachedAccess } from '@component-library/feature-manager';

// default everything to Vue 3 behavior, and only enable compat
// for certain features
configureCompat({
  MODE: 3,

  INSTANCE_SET: true,
  CUSTOM_DIR: true, // Custom directive hook names changed (e.g. bind -> beforeMount) - upstream library compat
  OPTIONS_DESTROYED: true,

  // Required for LiquorTree
  INSTANCE_EVENT_EMITTER: true,
  INSTANCE_SCOPED_SLOTS: true,
});

const app = createApp(App);

const pinia = createPinia();
app.use(pinia);
app.use(store);

app.use(router);
setAppSwitcherRouter(router);

//Set Axios configuration
app.use(VueAxios, axios);
axios.defaults.baseURL = `/`;

app.use(ToastsStorePlugin);

// Tooltips
app.use(VTooltip, {
  disposeTimeout: 0,
  themes: {
    tooltip: {
      html: true,
    },
  },
});

app.directive('click-outside', ClickOutside);

if (globalThis.loadedVueApp === true) {
  // Cannot import this app.ts file.
  // It will cause the Vue app to be reinitialized.
  throw new Error('Vue app already loaded');
}
globalThis.loadedVueApp = true;

// If explicitly enabled or not local environment
// Use the service worker.
if (
  import.meta.env.VITE_SERVICE_WORKER === 'true' ||
  import.meta.env.VITE_APP_ENV !== 'local'
) {
  import('./register-service-worker');
}

const basemapApiHosts = Object.values(hosts);

// Add project ID to requests
axios.interceptors.request.use(
  function (config) {
    if (!config.params) {
      config.params = {};
    }

    if (config.url?.includes('.s3.')) {
      if (config.headers) {
        delete config.headers['Authorization'];
      }
      return config;
    }

    if (
      basemapApiHosts.some((href) => config.url?.includes(href)) ||
      config.url?.match(/\/markers\/\d+\?/)
    ) {
      return config;
    }

    const isExternalUrl =
      !!config.url?.match(/^http(s)?/) &&
      !config.url.includes(import.meta.env.VITE_APP_URL);
    if (!config.params.project_id && !isExternalUrl) {
      const localStorageProjectId = localStorage.getItem('project_id');
      const routeProjectId =
        router.currentRoute.value?.params.project_id?.toString();
      if (routeProjectId && localStorageProjectId !== routeProjectId) {
        localStorage.setItem('project_id', routeProjectId);
      }
      config.params.project_id =
        localStorage.getItem('project_id') || undefined;
    }

    if (config.url?.includes('/safety/')) {
      config.url = `/api${config.url}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

//Intercept errors if project, or user session expired.
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (!error.response) {
      captureException(error);
      return Promise.reject(error);
    }

    const status = error.response.status,
      errorMessage = error.response.data.message;

    if (status !== 422) {
      captureException(error);
    }

    if (
      status == 401 &&
      !error.request.responseURL.includes(import.meta.env.VITE_APP_URL)
    ) {
      return true;
    }

    if (status == 401) {
      console.error('Clearing login as we received an 401 error status');
      if (auth.check()) {
        auth.logout();
      }
      useToastStore().error('Your session has expired.');
      window.location.replace(DATANEST_URL);
    }

    if (
      status == 403 &&
      (errorMessage == 'Project number has not been set.' ||
        errorMessage == 'You do not have access to this project.')
    ) {
      useToastStore().error(errorMessage);
      window.location.replace(DATANEST_URL);
    }

    if (status == 413) {
      useToastStore().error(
        'The uploaded file size exceeded our upload limit.'
      );
    }

    if (status === 419) {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      useToastStore().warning('Session expired, refreshing page...');
    }

    return Promise.reject(error);
  }
);

// Can force offline mode, with-out having to install service worker
if (import.meta.env.VITE_FORCE_OFFLINE_MODE === 'true') {
  Object.defineProperty(window.navigator, 'onLine', {
    get: () => false,
  });
}

if (
  import.meta.env.VITE_SENTRY_LARAVEL_DSN &&
  import.meta.env.VITE_SENTRY_LARAVEL_DSN != ''
) {
  createSentry();
}
parseCachedAccess();

(async () => {
  const offlineStorageManager = useOfflineStorageManagerStore();
  // @ts-expect-error this appears to require `.value` but stores shouldnt required this
  if (offlineStorageManager.offlineProjects.length === 0) {
    await offlineStorageManager.loadProjects();
  }

  initializeAnalytics();

  app.mount('#app');
})();
