<template>
  <ResponsiveOrSlideUp
    v-if="section != null"
    v-model="showSectionOptions"
    title="Section Options"
    :mobileSize="mobileSize"
    @close="clearSection"
  >
    <template #body>
      <label class="form-label" :class="{ 'text-danger': invalidNameError }">
        <InfoButton
          v-if="invalidNameError"
          class="me-2"
          :danger="true"
          backgroundColor="#FF0000"
          :info="invalidNameError"
        />
        Section Name
      </label>
      <input
        v-model="label"
        v-tooltip="invalidNameError"
        type="text"
        class="form-control w-100 mb-3"
        :class="{ 'text-danger is-invalid': invalidNameError }"
        @keyup.enter="clearSection"
      />

      <div v-if="allowPublic" class="form-group">
        <div class="form-check mb-0">
          <input
            id="public-form"
            v-model="public_form"
            class="form-check-input"
            type="checkbox"
          />
          <label class="form-check-label" for="public-form">
            Include on Public Form?
          </label>
        </div>

        <div class="form-check mb-0">
          <input
            :id="isShownOnNewPageCheckboxId"
            v-model="isShownOnNewPage"
            class="form-check-input"
            type="checkbox"
            :disabled="isShownOnNewPageCheckboxDisabled"
          />
          <label class="form-check-label" :for="isShownOnNewPageCheckboxId">
            Show on a new page?
          </label>
        </div>
      </div>

      <div class="form-group">
        <div class="form-check">
          <input
            id="repeat"
            v-model="repeatable"
            class="form-check-input"
            type="checkbox"
            @keyup.enter="clearSection"
          />
          <label class="form-check-label" for="repeat"> Is Repeatable </label>
        </div>
      </div>

      <div class="my-2">
        <a
          v-if="!showAdvancedOptions"
          href=""
          @click.prevent="toggleAdvancedOptions"
          >Show Advanced Options</a
        >
        <a v-else href="" @click.prevent="toggleAdvancedOptions"
          >Hide Advanced Options</a
        >
      </div>
      <div v-if="showAdvancedOptions" class="form-group mb-3">
        <label class="form-label" :class="{ 'text-danger': invalidNameError }">
          System Reference
          <InfoButton
            class="ms-2"
            info="This is the system reference used in Auto Doc code snippets.<br/><b>Tip:</b> You can put a short unique name here to avoid clashes with labels."
          />
        </label>
        <input
          v-model="system_reference"
          v-tooltip="invalidNameError"
          type="text"
          class="form-control w-100"
          :class="{ 'text-danger is-invalid': invalidNameError }"
          :placeholder="systemReferenceDefault"
          @keypress.enter="clearSection"
        />
      </div>
    </template>
  </ResponsiveOrSlideUp>
</template>

<script lang="ts" setup>
import { checkIsInPublicForm } from '@component-library/business-logic/section';
import ResponsiveOrSlideUp from './ResponsiveOrSlideUp.vue';
import slugify from '@component-library/utils/slugify';
import InfoButton from '@component-library/components/InfoButton.vue';
import { App, getSectionReferenceError } from '@component-library/gather';
import makeId from '@component-library/local-id.mjs';
import { computed, ref } from 'vue';
import Section from '@component-library/classes/Section';

const props = defineProps<{
  modelValue: Section;
  mobileSize: boolean;
  allowPublic: boolean;
  firstSectionInPublicForm?: Section;
  app: App;
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: Section): void;
  (event: 'clearSection'): void;
}>();

const showSectionOptions = ref(true),
  showAdvancedOptions = ref(false),
  isShownOnNewPageCheckboxId = ref(makeId());
const section = computed({
  get() {
    return props.modelValue;
  },
  set(updated) {
    emit('update:modelValue', updated);
  },
});

const label = computed({
  get() {
    return props.modelValue.label;
  },
  set(label) {
    emit('update:modelValue', { ...props.modelValue, label } as Section);
  },
});

const invalidNameError = computed(() => {
  return getSectionReferenceError(props.modelValue, props.app);
});

const repeatable = computed({
  get() {
    return props.modelValue.is_repeatable ? true : false;
  },
  set(is_repeatable) {
    emit('update:modelValue', {
      ...props.modelValue,
      is_repeatable,
    } as Section);
  },
});

const public_form = computed({
  get() {
    return props.modelValue.is_public_form;
  },
  set(is_public_form) {
    emit('update:modelValue', {
      ...props.modelValue,
      is_public_form,
    } as Section);
  },
});

const isShownOnNewPage = computed({
  get() {
    return props.modelValue.is_shown_on_new_page;
  },
  set(value) {
    emit('update:modelValue', {
      ...props.modelValue,
      is_shown_on_new_page: value,
    } as Section);
  },
});

const isShownOnNewPageCheckboxDisabled = computed(() => {
  return (
    !checkIsInPublicForm(section.value) ||
    section.value === props.firstSectionInPublicForm
  );
});

const system_reference = computed({
  get() {
    return props.modelValue.system_reference;
  },
  set(system_reference) {
    emit('update:modelValue', {
      ...props.modelValue,
      system_reference,
    } as Section);
  },
});

const systemReferenceDefault = computed(() => {
  if (!props.modelValue.label) {
    return '';
  }
  return slugify(props.modelValue.label);
});

function clearSection() {
  emit('clearSection');
}
function toggleAdvancedOptions() {
  showAdvancedOptions.value = !showAdvancedOptions.value;
}
</script>
