import { FieldTypeIds, getFieldTypeById } from './fields';
import { App, GatherField, Section } from './gather';
import SectionClass from './classes/Section';
import slugify from './utils/slugify';

export interface SnippetTab {
  id: number;
  title: string;
  snippets: Snippet[];
  originalData?: App;
}

export interface Snippet {
  app_id: number | null;
  label: string;
  snippet: string;
  type: string;
  children?: Snippet[];
}

export function createSnippet(
  app_id: number | null,
  label: string,
  snippet: string,
  type?: string,
  children?: Snippet[]
): Snippet {
  return {
    app_id,
    label,
    snippet,
    type: type ?? '',
    children,
  };
}

export function getSystemReference(
  obj:
    | App
    | Section
    | SectionClass
    | GatherField
    | { system_reference?: string; title?: string; label?: string }
) {
  if ('title' in obj) {
    return slugify(obj.system_reference, obj.title ?? '');
  }
  return slugify(obj.system_reference, obj.label ?? '');
}

let linkedAppDepth = 0;
export function buildSnippetTabFromData(
  gatherSchemaStore: any,
  allApps: App[],
  app: App,
  many = true,
  tab: null | SnippetTab = null
): SnippetTab | null {
  linkedAppDepth = 0;
  if (!app.system_reference && !app.title) {
    return null;
  }

  const snippets = buildSnippetsFromApp(app, many);

  for (let field of gatherSchemaStore.referenceFields) {
    if (field.options?.template_tab_title === app.title) {
      const referenceField =
        slugify(
          field.section.app.system_reference,
          field.section.app.title ?? ''
        ) +
        '.' +
        slugify(field.section.system_reference, field.section.label ?? '') +
        '.' +
        slugify(field.system_reference, field.label ?? '');

      const itemId =
        (many
          ? '$a_' + getSystemReference(app)
          : 'first(' + getSystemReference(app) + ')') + '?.id';
      const fieldAppFull =
        allApps.find((t) => t.title === field.section.app.title) ||
        field.section.app;

      const refAppSnippets = buildSnippetsFromApp(
        fieldAppFull,
        true,
        'ref_',
        'refs("' + referenceField + '", ' + itemId + ')'
      );

      if (many && snippets.length) {
        snippets[0].children = snippets[0].children || [];
        for (const appSnippet of refAppSnippets) {
          snippets[0].children.push(appSnippet);
        }
      } else {
        for (const appSnippet of refAppSnippets) {
          snippets.push(appSnippet);
        }
      }
    }
  }

  if (tab) {
    tab.snippets = snippets;
  }

  const snippetTab: SnippetTab = {
    originalData: app,
    id: app.id,
    title: app.title ?? 'Untitled App',
    snippets,
  };

  cachedSnippetTabs.set(app.id, snippetTab);
  linkedAppDepth = 0;

  return snippetTab;
}

export function buildSnippetsFromApp(
  app: App,
  many = true,
  itemPrefix = 'a_',
  overrideArr?: string
): Snippet[] {
  const slugAppsTitle = slugify(app.system_reference, app.title ?? '');
  const slugAppSingle = itemPrefix + slugAppsTitle;
  let appPrefix = '$' + slugAppSingle + '.';

  let arrayCode = overrideArr ?? 'arr(' + slugAppsTitle + ')';

  const appSnippet = createSnippet(
    app.id,
    'Foreach ' + app.title,
    '{FOR ' +
      slugAppSingle +
      ' IN ' +
      arrayCode +
      '}\n\n{END-FOR ' +
      slugAppSingle +
      '}',
    'Data Point',
    []
  );
  const sectionSnippets = appSnippet.children!;

  if (!many) {
    appPrefix = '';
    sectionSnippets.push(
      createSnippet(
        app.id,
        app.title + ' identifier',
        '{first(' + slugAppsTitle + ').custom_title}',
        'Text'
      )
    );
    sectionSnippets.push(
      createSnippet(
        app.id,
        app.title + ' Latitude (WGS84)',
        '{first(' + slugAppsTitle + ').latitude}',
        'Text'
      )
    );
    sectionSnippets.push(
      createSnippet(
        app.id,
        app.title + ' Longitude (WGS84)',
        '{first(' + slugAppsTitle + ').longitude}',
        'Text'
      )
    );
  } else {
    sectionSnippets.push(
      createSnippet(
        app.id,
        app.title + ' identifier',
        '{' + appPrefix + 'custom_title}',
        'Text'
      )
    );
    sectionSnippets.push(
      createSnippet(
        app.id,
        app.title + ' Latitude (WGS84)',
        '{' + appPrefix + 'latitude}',
        'Text'
      )
    );
    sectionSnippets.push(
      createSnippet(
        app.id,
        app.title + ' Longitude (WGS84)',
        '{' + appPrefix + 'longitude}',
        'Text'
      )
    );
  }

  for (let section of app.sections ?? []) {
    linkedAppDepth = 0;

    if (!section.is_repeatable) {
      const slugSection = slugify(section.system_reference, section.label);
      for (let field of section.template_fields ?? []) {
        parseFieldSnippets(
          app.id,
          field,
          sectionSnippets,
          appPrefix + slugSection
        );
      }
      continue;
    }

    const sectionSnippet = buildSectionSnippet(
      app.id,
      appPrefix,
      section,
      sectionSnippets
    );
    if (sectionSnippet) {
      sectionSnippets.push(sectionSnippet);
    }
  }

  return many ? [appSnippet] : sectionSnippets;
}

function buildSectionSnippet(
  appId: number,
  appPrefix,
  section: Section,
  parentChildren: Snippet[],
  repeatingPrefix = 'a_'
) {
  let slugSection = slugify(section.system_reference, section.label),
    slugSectionSingle = repeatingPrefix + slugSection,
    fieldSnippets = [];

  if (!section.is_repeatable) {
    for (let field of section.template_fields ?? []) {
      parseFieldSnippets(
        appId,
        field,
        parentChildren,
        appPrefix + '.' + slugSection
      );
    }
    return;
  }

  for (let field of section.template_fields ?? []) {
    parseFieldSnippets(appId, field, fieldSnippets, '$' + slugSectionSingle);
  }

  return createSnippet(
    appId,
    'Repeating: ' + section.label,
    '{FOR ' +
      slugSectionSingle +
      ' IN arr(' +
      appPrefix +
      slugSection +
      ')}\n\n{END-FOR ' +
      slugSectionSingle +
      '}',
    'Section',
    fieldSnippets
  );
}

export function parseFieldSnippets(
  appId: number,
  field: GatherField,
  snippets: Snippet[],
  parentSlug
) {
  let slugField = slugify(field.system_reference, field.label);
  if (field.field_type_id === FieldTypeIds.CAPTION) {
    return;
  } else if (
    field.field_type_id === FieldTypeIds.MEDIA ||
    field.field_type_id === FieldTypeIds.SIGNATURE ||
    field.field_type_id === FieldTypeIds.DRAWING
  ) {
    snippets.push(
      createSnippet(
        appId,
        field.label + (field.options?.has_multiple ? ' (first)' : ''),
        '{IF (' +
          parentSlug +
          '?.' +
          slugField +
          ')}{IMAGE resizeImage(' +
          parentSlug +
          '?.' +
          slugField +
          '_image, 5, 5)}{END-IF}',
        getFieldTypeById(field.field_type_id)?.display_name ?? ''
      )
    );
    if (field.options?.has_multiple) {
      snippets.push(
        createSnippet(
          appId,
          field.label + (field.options?.has_multiple ? ' (last)' : ''),
          '{IF (' +
            parentSlug +
            '?.' +
            slugField +
            ')}{IMAGE resizeImage(last(' +
            parentSlug +
            '?.' +
            slugField +
            '_images || []), 5, 5)}{END-IF}',
          getFieldTypeById(field.field_type_id)?.display_name ?? ''
        )
      );
      snippets.push(
        createSnippet(
          appId,
          field.label + ' (multiple)',
          '{FOR image IN (' +
            parentSlug +
            '?.' +
            slugField +
            '_images || [])}{IMAGE resizeImage($image, 5, 5)}{END-FOR image}',
          getFieldTypeById(field.field_type_id)?.display_name
        )
      );
    }
  } else if (field.field_type_id === FieldTypeIds.TEXT) {
    snippets.push(
      createSnippet(
        appId,
        field.label + (field.options?.is_richtext ? ' (inline)' : ''),
        '{clean(' + parentSlug + '.' + slugField + ')}',
        getFieldTypeById(field.field_type_id)?.display_name
      )
    );
    if (field.options?.is_richtext) {
      snippets.push(
        createSnippet(
          appId,
          field.label + ' (formatted)',
          '{HTML h(' +
            parentSlug +
            '.' +
            slugField +
            ', "font-family: \'Arial\'")}',
          getFieldTypeById(field.field_type_id)?.display_name
        )
      );
    }
  } else if (
    field.field_type_id === FieldTypeIds.NUMBER ||
    field.field_type_id === FieldTypeIds.EXPRESSION
  ) {
    snippets.push(
      createSnippet(
        appId,
        field.label,
        '{roundTo(' +
          parentSlug +
          '.' +
          slugField +
          ', ' +
          (field.options?.maxDecimals || 4) +
          ')}',
        getFieldTypeById(field.field_type_id)?.display_name
      )
    );
  } else if (field.field_type_id === FieldTypeIds.CHECKBOX) {
    snippets.push(
      createSnippet(
        appId,
        field.label,
        '{isYes(' + parentSlug + '.' + slugField + ') ? "Yes" : "No"}',
        getFieldTypeById(field.field_type_id)?.display_name
      )
    );
  } else if (field.field_type_id === FieldTypeIds.DROPDOWN) {
    if (field.options?.has_multiple) {
      snippets.push(
        createSnippet(
          appId,
          field.label + ' (a, b, c)',
          '{join(' + parentSlug + '.' + slugField + ', ", ")}',
          getFieldTypeById(field.field_type_id)?.display_name
        )
      );
      snippets.push(
        createSnippet(
          appId,
          field.label + ' (a, b and c)',
          '{join(' + parentSlug + '.' + slugField + ', ", ", " and ")}',
          getFieldTypeById(field.field_type_id)?.display_name
        )
      );
      snippets.push(
        createSnippet(
          appId,
          field.label + ' (new lines)',
          '{join(' + parentSlug + '.' + slugField + ', "\\n")}',
          getFieldTypeById(field.field_type_id)?.display_name
        )
      );
      snippets.push(
        createSnippet(
          appId,
          field.label + ' (FOR ... END-FOR)',
          '{FOR ' +
            slugField +
            '_value IN arr(' +
            parentSlug +
            '.' +
            slugField +
            ')}...{$' +
            slugField +
            '_value}...{END-FOR ' +
            slugField +
            '_value}',
          getFieldTypeById(field.field_type_id)?.display_name
        )
      );
    } else {
      snippets.push(
        createSnippet(
          appId,
          field.label,
          '{first(' + parentSlug + '?.' + slugField + ')}',
          getFieldTypeById(field.field_type_id)?.display_name
        )
      );
    }
  } else if (field.field_type_id === FieldTypeIds.REFERENCE) {
    const relatedPrefix = 'related(' + parentSlug + '?.' + slugField + ')?';
    const relatedSnippets = [
      createSnippet(
        appId,
        field.label,
        '{' + relatedPrefix + '.custom_title}',
        (field.options?.template_tab_title ?? 'Linked App') + ' - item title'
      ),
      createSnippet(
        appId,
        field.label,
        '{' + relatedPrefix + '.latitude}',
        (field.options?.template_tab_title ?? 'Linked App') + ' - Latitude'
      ),
      createSnippet(
        appId,
        field.label,
        '{' + relatedPrefix + '.longitude}',
        (field.options?.template_tab_title ?? 'Linked App') + ' - Longitude'
      ),
    ];

    const referencedFieldsSnippet = createSnippet(
      appId,
      field.label,
      '',
      (field.options?.template_tab_title ?? 'Linked App') + ' - fields',
      relatedSnippets
    );

    if (linkedAppDepth >= 10) {
      snippets.push(referencedFieldsSnippet);
      console.warn(
        'Maximum linked app depth reached, infinite loop of references?',
        linkedAppDepth
      );
      return;
    }
    linkedAppDepth++;
    referencedFieldsSnippet.children = resolveReferencedFieldSnippets(
      appId,
      field,
      referencedFieldsSnippet,
      relatedPrefix
    );

    snippets.push(referencedFieldsSnippet);
  } else if (field.field_type_id === FieldTypeIds.DATE) {
    let fieldType = field.options?.type ?? 'datetime';
    if (fieldType.includes('date')) {
      snippets.push(
        createSnippet(
          appId,
          field.label + ' (default format)',
          '{' + parentSlug + '.' + slugField + '}',
          getFieldTypeById(field.field_type_id)?.display_name
        )
      );
      snippets.push(
        createSnippet(
          appId,
          field.label + ' (YYYY-MM-DD)',
          '{dateFormat(' +
            parentSlug +
            '.' +
            slugField +
            '_utc, "YYYY-MM-DD")}',
          getFieldTypeById(field.field_type_id)?.display_name
        )
      );
      snippets.push(
        createSnippet(
          appId,
          field.label + ' (MM-DD-YYYY)',
          '{dateFormat(' +
            parentSlug +
            '.' +
            slugField +
            '_utc, "MM-DD-YYYY")}',
          getFieldTypeById(field.field_type_id)?.display_name
        )
      );
      snippets.push(
        createSnippet(
          appId,
          field.label + ' (DD-MM-YYYY)',
          '{dateFormat(' +
            parentSlug +
            '.' +
            slugField +
            '_utc, "DD-MM-YYYY")}',
          getFieldTypeById(field.field_type_id)?.display_name
        )
      );
    }
    if (fieldType.includes('time')) {
      snippets.push(
        createSnippet(
          appId,
          field.label + ' (time)',
          '{' + parentSlug + '.' + slugField + '_time}',
          getFieldTypeById(field.field_type_id)?.display_name
        )
      );
    }
  } else {
    snippets.push(
      createSnippet(
        appId,
        field.label,
        '{' + parentSlug + '.' + slugField + '}',
        getFieldTypeById(field.field_type_id)?.display_name
      )
    );
  }
}

const cachedSnippetTabs = new Map<number, SnippetTab>();

function resolveReferencedFieldSnippets(
  appId: number,
  field: GatherField,
  referencedFieldsSnippet: Snippet,
  relatedPrefix: string
) {
  const appTitle = field.options?.template_tab_title;
  const relatedSnippets = referencedFieldsSnippet.children ?? [];

  for (const snippetTab of cachedSnippetTabs.values()) {
    if (snippetTab.title === appTitle) {
      for (let section of snippetTab.originalData?.sections ?? []) {
        const sectionSnippet = buildSectionSnippet(
          appId,
          relatedPrefix + (section.is_repeatable ? '?.' : ''),
          section,
          relatedSnippets,
          'ref_'
        );
        if (sectionSnippet) {
          relatedSnippets.push(sectionSnippet);
        }
      }
      return relatedSnippets;
    }
  }
  return relatedSnippets;
}

export function formatSystemReferenceChain(systemReferenceChain) {
  let str = '';
  if (!systemReferenceChain.split) {
    return systemReferenceChain;
  }
  let parts = systemReferenceChain.split('.');
  for (let part of parts) {
    if (str !== '') {
      str += ' > ';
    }
    str += part
      .split('_')
      // Capitalize first letter of each word
      // Remove question marks from end of section names
      .map((s) => (s[0].toUpperCase() + s.slice(1)).replace(/\?$/, ''))
      .join(' ');
  }

  return str;
}
