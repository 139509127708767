<template>
  <Modal :full="true" :supportTutorialId="supportTutorialId" @close="close">
    <template #header> Expression Editor </template>

    <template #default>
      <div class="expression-editor" style="height: calc(100vh - 300px)">
        <NotAvailable
          v-if="!fields.length"
          class="text-center h-100 d-flex align-items-center justify-content-center flex-column my-3"
        />

        <div v-else class="d-flex flex-column">
          <div class="mb-3">
            <h6>
              Expression
              <InfoButton
                class="ms-2"
                :info="
                  'An expression is comprised of fields, operators and values. Each value could be text,' +
                  'a number, a date or a Yes / No choice. The evaluation result of expressions could be either a number' +
                  'or a Yes / No choice. Use the tabs below to add fields, operators and values into an expression.'
                "
                container="div.expression-editor"
              />
            </h6>
            <TagInput
              :tags="tags"
              :fields="fields"
              :invalid="!resultType"
              :checkHasMissingField="checkHasMissingField"
              :selectedParamGroupPosition="selectedParamGroupPosition"
              @add="handleInputAdd"
              @removeTag="(index) => tags.splice(index, 1)"
              @moveTag="
                ({ from, to }) => tags.splice(to, 0, tags.splice(from, 1)[0])
              "
              @paramGroupSelect="handleParamGroupSelect($event)"
              @argDelete="handleArgDelete($event)"
            />
          </div>

          <div
            class="row flex-grow-1"
            style="overflow-y: auto; min-height: 0px"
          >
            <div class="h-100 col-12 col-md-6 mb-3 mb-md-0">
              <TabbedInputs
                class="h-100"
                :fields="fields"
                :sections="sections"
                @add="handleInputAdd"
              />
            </div>

            <div class="h-100 col-12 col-md-6">
              <h6>
                Test Result
                <InfoButton
                  class="ms-2"
                  info="Example of how your expression using the example values you specified"
                  container="div.expression-editor"
                />
              </h6>
              <code style="white-space: pre-wrap">{{
                testResultDisplayText
              }}</code>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="fields.length" #footer>
      <div class="row flex-grow-1">
        <div class="col-12 col-md-6">
          <button class="btn btn-outline-secondary w-100" @click="close">
            Close
          </button>
        </div>
        <div class="col-12 col-md-6">
          <button
            type="submit"
            class="btn btn-primary w-100"
            :disabled="!resultType"
            @click="handleClickSave"
          >
            Save Expression
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import {
  addArg,
  convertTagsToString,
  evaluateValues,
  findCustomFunctionById,
  getEligibleFields,
  getFields,
  getResultDisplayText,
  getValuesFromTagsWithFunction,
  parseLegacyExpression,
  resolveFieldFromValuePairs,
} from '@component-library/business-logic/expression';
import {
  ACTION_CURRENT,
  ACTION_NEXT,
  ACTION_PREVIOUS,
  INPUT_TYPE_CUSTOM_FUNCTION,
  INPUT_TYPE_FIELD,
  INPUT_TYPE_OPERATOR,
  INPUT_TYPE_VALUE,
  ParamType,
  RESULT_TYPE_BOOLEAN,
  RESULT_TYPE_NUMBER,
  RESULT_TYPE_TEXT,
  TAG_TYPE_CUSTOM_FUNCTION,
  TAG_TYPE_FIELD,
  TAG_TYPE_OPERATOR,
  valueType2TagType,
} from '@component-library/business-model/expression';
import InfoButton from '@component-library/components/InfoButton.vue';
import Modal from '@component-library/components/Modal.vue';
import _cloneDeep from 'lodash/cloneDeep';
import NotAvailable from './NotAvailable.vue';
import TabbedInputs from './TabbedInputs.vue';
import TagInput from './TagInput.vue';

export default {
  name: 'ExpressionEditorModal',
  props: {
    dataTab: Object,
    value: Object,
    sections: Array,
  },
  emits: ['updateExpression', 'close'],
  data: () => ({
    tags: [],
    fields: [],
    supportTutorialId: parseInt(
      import.meta.env.VITE_EXPRESSION_EDITOR_SUPPORT_TUTORIAL_ID || 168,
      10
    ),
    selectedParamGroupPosition: {
      tagIndex: null,
      paramGroupIndex: null,
    },
  }),
  components: {
    Modal,
    NotAvailable,
    TagInput,
    TabbedInputs,
    InfoButton,
  },
  computed: {
    testResult() {
      // A field used by the expression could have been deleted.
      const hasMissingField = this.tags.some((t) =>
        this.checkHasMissingField(t)
      );
      if (hasMissingField) {
        return null;
      }

      const values = getValuesFromTagsWithFunction(
        this.tags,
        (fieldId, action) => {
          const field = this.findFieldById(fieldId);
          const {
            testValue: { value, value2 },
          } = field;
          const valuePair = { value, value2 };
          const valuePairs = [ACTION_PREVIOUS, ACTION_NEXT].includes(action)
            ? [valuePair, valuePair]
            : [valuePair];
          const sectionIndex = action === ACTION_PREVIOUS ? 1 : 0;
          return resolveFieldFromValuePairs(
            field,
            action,
            valuePairs,
            sectionIndex
          );
        }
      );

      if (!values.length) {
        return null;
      }

      try {
        return evaluateValues(values);
      } catch (e) {}

      return null;
    },
    resultType() {
      if (
        typeof this.testResult === 'number' &&
        !Number.isNaN(this.testResult)
      ) {
        return RESULT_TYPE_NUMBER;
      } else if (typeof this.testResult === 'boolean') {
        return RESULT_TYPE_BOOLEAN;
      } else if (typeof this.testResult === 'string') {
        return RESULT_TYPE_TEXT;
      }

      return null;
    },
    testResultDisplayText() {
      return this.resultType
        ? `${convertTagsToString(
            this.tags,
            this.fields
          )} = ${getResultDisplayText(this.value, this.testResult)}`
        : 'Not a valid expression';
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    checkHasMissingField(tag) {
      if (tag.type === TAG_TYPE_FIELD) {
        return !this.findFieldById(tag.field_id);
      } else if (tag.type === TAG_TYPE_CUSTOM_FUNCTION) {
        return tag.value.argGroups.some((ag) =>
          ag.some((arg) => {
            return arg.type === 1 && !this.findFieldById(arg.fieldId);
          })
        );
      }

      return false;
    },
    checkIsParamGroupSelected() {
      const { tagIndex, paramGroupIndex } = this.selectedParamGroupPosition;
      return (
        typeof tagIndex === 'number' && typeof paramGroupIndex === 'number'
      );
    },
    findFieldById(id) {
      return this.fields.find((f) => f.id === id);
    },
    handleInputAdd(input) {
      if (input.type === INPUT_TYPE_FIELD) {
        const field = this.findFieldById(input.value);

        if (!this.checkIsParamGroupSelected()) {
          this.tags.push({
            type: TAG_TYPE_FIELD,
            field_id: field.id,
            action: field.action ?? ACTION_CURRENT,
          });
          return;
        }

        const { tagIndex, paramGroupIndex } = this.selectedParamGroupPosition;
        const tag = this.tags[tagIndex];
        const { id, argGroups } = tag.value;
        const customFunction = findCustomFunctionById(id);
        const { maxCount } = customFunction.paramGroups[paramGroupIndex];
        const arg = {
          type: ParamType.FIELD,
          fieldId: field.id,
          action: field.action,
        };
        const argGroup = argGroups[paramGroupIndex];
        argGroups[paramGroupIndex] = addArg(argGroup, arg, maxCount);
      } else if (input.type === INPUT_TYPE_OPERATOR) {
        this.tags.push({
          type: TAG_TYPE_OPERATOR,
          value: input.value,
        });
      } else if (input.type === INPUT_TYPE_VALUE) {
        const { value, valueType } = input;

        if (!this.checkIsParamGroupSelected()) {
          this.tags.push({
            type: valueType2TagType(valueType),
            value,
          });
          return;
        }

        const { tagIndex, paramGroupIndex } = this.selectedParamGroupPosition;
        const tag = this.tags[tagIndex];
        const { id, argGroups } = tag.value;
        const customFunction = findCustomFunctionById(id);
        const { maxCount } = customFunction.paramGroups[paramGroupIndex];
        const arg = {
          type: ParamType.LITERAL_TEXT,
          value: encodeURIComponent(value), // Stop the backend from converting a string with just spaces into null.
        };
        const argGroup = tag.value.argGroups[paramGroupIndex];
        tag.value.argGroups[paramGroupIndex] = addArg(argGroup, arg, maxCount);
      } else if (input.type === INPUT_TYPE_CUSTOM_FUNCTION) {
        const customFunction = findCustomFunctionById(input.value);
        this.tags.push({
          type: TAG_TYPE_CUSTOM_FUNCTION,
          value: {
            id: customFunction.id,
            argGroups: customFunction.paramGroups.map((pg, pgIndex) =>
              customFunction.getDefaultArgGroup(pgIndex)
            ),
          },
        });
      }
    },
    handleClickSave() {
      this.$emit('updateExpression', {
        tags: this.tags,
        resultType: this.resultType,
      });
      this.$nextTick(() => {
        this.close();
      });
    },
    handleParamGroupSelect(selectedParamGroupPosition) {
      this.selectedParamGroupPosition = selectedParamGroupPosition;
    },
    handleArgDelete({ tagIndex, paramGroupIndex, argIndex }) {
      this.tags[tagIndex].value.argGroups[paramGroupIndex].splice(argIndex, 1);
    },
  },
  mounted() {
    const fields = getFields(this.sections);
    let { expression } = this.value.options;
    if (typeof expression === 'string') {
      expression = parseLegacyExpression(expression, fields);
    }
    if (expression) {
      this.tags = _cloneDeep(expression.tags);
    }
    this.fields = getEligibleFields(fields, {
      ...this.value,
      options: {
        ...this.value.options,
        expression,
      },
    });
  },
};
</script>
