<template>
  <div>
    <div name="modal" role="dialog">
      <div
        id="mainModal"
        :class="['modal modal-mask fade', { show: visible }]"
        @mousedown="outsideClose"
      >
        <div
          :class="[
            'modal-dialog modal-dialog-centered modal-dialog-scrollable',
            {
              'full-modal': full,
              'modal-lg': half,
              'full-height': stretch,
            },
          ]"
          role="document"
        >
          <div class="modal-content" @click.stop @mousedown.stop>
            <div v-if="loading" class="text-center py-4">
              <Spinner large />
            </div>
            <template v-else>
              <div
                v-if="(!notClosable || supportTutorialId) && !hideHeader"
                class="modal-header d-flex justify-content-between align-items-center"
              >
                <div :class="['modal-title', titleClass]" data-cy="modal-title">
                  <slot name="header" data-cy="header" />
                </div>

                <div class="d-flex align-items-center">
                  <div
                    v-if="supportTutorialId"
                    class="me-2 help clickable"
                    @click="handleSupportTutorialOpen"
                  >
                    <i class="fas fa-question-circle"></i>
                  </div>
                  <div class="close clickable" data-cy="close" @click="close">
                    <i class="fas fa-times"></i>
                  </div>
                </div>
              </div>
              <slot />
            </template>
          </div>
        </div>
      </div>
    </div>
    <slot name="other" />
  </div>
</template>

<script>
import * as utils from '@component-library/utils';
import { DATANEST_URL } from '@component-library/env';
import Spinner from '@component-library/components/Spinner.vue';

/**
 * @deprecated Use component-library/components/Modal.vue
 */
export default {
  name: 'Modal',
  props: [
    'show',
    'full',
    'half',
    'loading',
    'notClosable',
    'titleClass',
    'hideHeader',
    'isDelete',
    'noOutsideClick',
    'supportTutorialId',
    'stretch',
  ],
  data: () => ({
    visible: false,
  }),
  watch: {
    show() {
      this.setVisible();
    },
  },
  components: { Spinner },
  methods: {
    close() {
      if (this.loading) {
        return;
      }

      this.$emit('close');
    },
    async setVisible() {
      await new Promise((resolve) => {
        setTimeout(() => {
          this.visible = this.show;
          resolve();
        }, 150);
      });
      await this.$nextTick();
      this.$emit('visibleChanged', this.visible);
    },
    outsideClose() {
      if (this.noOutsideClick) {
        return;
      }
      this.close();
    },
    handleSupportTutorialOpen() {
      const url = `${DATANEST_URL}/support/tutorial/${this.supportTutorialId}`;
      utils.openNewTab(url);
    },
  },
  mounted() {
    console.warn('Deprecated: Use component-library/components/Modal.vue');

    document.addEventListener('keydown', (e) => {
      if (this.show && e.keyCode == 27) {
        this.close();
      }
    });

    this.setVisible();
  },
};
</script>
