<script lang="ts" setup>
import { getProjectUrlPrefix, useProjectStore } from '../store/project';
import AlertBox from './AlertBox.vue';
const projectStore = useProjectStore();
</script>

<template>
  <AlertBox type="danger">
    Gather Unavailable: The project address is not configured please got to
    <a
      :href="
        getProjectUrlPrefix(projectStore.project?.uuid) + '/project-settings'
      "
      >Project Settings</a
    >
  </AlertBox>
</template>
