import useMapsApi from '@/js/composables/useMapsApi';
import {
  StackableModalType,
  checkIsSampleModal,
  getTopModal,
} from '@/js/types/modal-stack';
import { DEFAULT_ZOOM } from '@maps/lib/olbm/common/view';
import { parseMarkerIdentifier } from '@maps/lib/olbm/layer/sample/utils';
import {
  checkIsTempLayerModel,
  duplicateLayerModel,
  getLayerTitle,
} from '@maps/lib/olbm/layer/utils';
import { Color, DrawingType, LayerType } from '@maps/lib/olbm/types';
import _omit from 'lodash/omit';
import TrackingActivity from '../helpers/TrackingActivity';
import EventBus from '@component-library/EventBus';

class ActionEvents {
  static eventNames = [
    'confirmDraw',
    'cancelDraw',
    'openDraw',
    'closeDraw',
    'startDrawMarker',
    'startNonSpatial',
    'startDrawPoly',
    'startDrawPolyWithLayerType',
    'gotoMyLocation',
    'backToAddress',
    'openTrackingActivityModal',
    'closeTrackingActivityModal',
    'startTracking',
    'stopTracking',
    'showLayerStylingOffcanvas',
    'togglePoiEditing',
    'editPlainShape',
    'duplicatePlainShape',
    'deletePlainShapeConfirmed',
    'setTemplateToSelect',
  ];

  get map() {
    return this.viewer.getMap();
  }

  constructor(viewer) {
    this.viewer = viewer;
    this.trackingActivity = new TrackingActivity(this.viewer);

    ActionEvents.eventNames.forEach((event) => {
      EventBus.$on(event, (data) => {
        this[event](data);
      });
    });
  }

  confirmDraw() {
    const im = this.map.getInteractionManager();

    // Only used for duplicating of a point sample
    if (this.viewer.isDuplicating) {
      const allEditStates = im.getAllEditStates();
      const [layerUid] = Object.keys(allEditStates);
      const [
        { position, labelPosition, angle, iconOpacity, iconOpacityOverride },
      ] = allEditStates[layerUid];
      const { sampleDataToModify } = this.viewer;
      EventBus.$emit('setSampleDataToModify', {
        sample_id: sampleDataToModify.id,
        latlng: this.map.toLegacyLatLng(position),
        label_position: labelPosition,
        icon_rotation: angle,
        icon_opacity: iconOpacity,
        icon_opacity_override: iconOpacityOverride,
      });

      im.endSession();
      this.viewer.isDuplicating = false;
      this.viewer.isMoving = false;

      EventBus.$emit('duplicateSample');
      return;
    }

    if (this.viewer.isEditing) {
      const { sampleDataToModify } = this.viewer;

      // Confirm the editing of a plain shape
      if (!sampleDataToModify.id) {
        const allEditStates = im.getAllEditStates();
        const [layerUid] = Object.keys(allEditStates);
        const [{ layerModelId }] = allEditStates[layerUid];
        const layer = im.layerManager.findLayerByModelId(layerModelId);
        const geojson = layer.toGeoJSON();
        const layerModel = this.map.findLayerModelById(layerModelId);
        const isTemp = checkIsTempLayerModel(layerModel);
        im.endSession();
        const figure = this.map.getSelectedFigure();
        const { savePlainShape } = useMapsApi();
        savePlainShape(figure.id, !isTemp ? layerModelId : undefined, geojson)
          .then((layerModel) => {
            if (!isTemp) {
              this.map.updateLayerModel(layerModelId, layerModel);
              return layerModel;
            } else {
              return this.map.addLayerModel(figure, layerModel);
            }
          })
          .catch((e) => {
            this.viewer.$toastStore.error(
              `The layer failed to save, try again.`
            );

            console.error(e);
          });
        this.viewer.isEditing = false;
        return;
      }

      const newSampleDataToModify = _omit(
        sampleDataToModify,
        'area_figure_layer'
      );

      if (sampleDataToModify.area_figure_layer || sampleDataToModify.geojson) {
        const allEditStates = im.getAllEditStates();
        const [layerUid] = Object.keys(allEditStates);
        const [{ layerModelId }] = allEditStates[layerUid];
        const layer = im.layerManager.findLayerByModelId(layerModelId);
        newSampleDataToModify.geojson = layer.toGeoJSON();
      } else {
        const allEditStates = im.getAllEditStates();
        const [layerUid] = Object.keys(allEditStates);
        const [
          { position, labelPosition, angle, iconOpacity, iconOpacityOverride },
        ] = allEditStates[layerUid];
        newSampleDataToModify.latlng = this.map.toLegacyLatLng(position);
        newSampleDataToModify.label_position = labelPosition;
        newSampleDataToModify.icon_rotation = angle;
        newSampleDataToModify.icon_opacity = iconOpacity;
        newSampleDataToModify.icon_opacity_override = iconOpacityOverride;
        this.map.deleteSampleStyleFromCache(newSampleDataToModify);
      }
      EventBus.$emit('setSampleDataToModify', newSampleDataToModify);

      if (!this.viewer.isAllowCollectionOnPoiAvailable) {
        this.viewer.isEditing = false;
        EventBus.$emit('updateSampleLocation');
      } else {
        this.viewer.openSampleModal();
      }
      return;
    }

    // moving / drawing sample location
    if (this.viewer.isMoving) {
      const allEditStates = im.getAllEditStates();
      const [layerUid] = Object.keys(allEditStates);
      if (!layerUid) {
        this.viewer.$toastStore.error('Please draw a marker on the map.');
        return;
      }

      im.endSession();
      this.viewer.isMoving = false;

      const payload = () => {
        const { sampleDataToModify } = this.viewer;
        const [{ position, iconOpacity, iconOpacityOverride }] =
          allEditStates[layerUid];
        EventBus.$emit('setSampleDataToModify', {
          id: sampleDataToModify?.id,
          template_tab_id: sampleDataToModify?.template_tab_id,
          latlng: this.map.toLegacyLatLng(position),
          icon_opacity: iconOpacity,
          icon_opacity_override: iconOpacityOverride,
        });
        this.viewer.openSampleModal();
      };
      if (!this.viewer.$refs.sampleModal.isAssigningShape) {
        this.viewer.pushToModalStack({
          type: StackableModalType.SampleModal,
          payload,
        });
      } else {
        payload();
      }
      return;
    }

    // drawing an area or a line
    if (this.viewer.isDrawing) {
      const { isPlainLayer = false } = im.getData();
      const allEditStates = im.getAllEditStates();
      const [layerUid] = Object.keys(allEditStates);
      if (!layerUid) {
        const shapeName = im.getDraw().getShapeName();
        this.viewer.$toastStore.error(`Please draw a ${shapeName} on the map.`);
        return;
      }

      const [{ layerModelId }] = allEditStates[layerUid];
      const layer = im.layerManager.findLayerByModelId(layerModelId);
      const geojson = layer.toGeoJSON();
      const previousLayerId = im.layerManager.findPreviousLayerId(layerModelId);

      im.endSession();
      this.viewer.drawingType = undefined;
      this.viewer.isDrawing = false;
      this.viewer.isTrackingAvailable = false;

      if (isPlainLayer) {
        const { savePlainShape } = useMapsApi();
        const figure = this.map.getSelectedFigure();
        savePlainShape(figure.id, undefined, geojson)
          .then((layerModel) => {
            return this.map.addLayerModel(figure, layerModel);
          })
          .then((layerModel) => {
            EventBus.$emit('showLayerStylingOffcanvas', layerModel.id);
          });
      } else {
        const payload = () => {
          const { sampleDataToModify } = this.viewer;
          const center = im.layerManager.getLayerCenter(layer);
          const latlng = this.map.toLegacyLatLng(center);
          EventBus.$emit('setSampleDataToModify', {
            id: sampleDataToModify?.id,
            template_tab_id: sampleDataToModify?.template_tab_id,
            latlng,
            geojson: {
              ...geojson,
              properties: {
                ...geojson.properties,
                layerId: layerModelId,
              },
            },
            after_layer_id: previousLayerId,
          });
          this.viewer.openSampleModal();
        };
        if (!this.viewer.$refs.sampleModal.isAssigningShape) {
          this.viewer.pushToModalStack({
            type: StackableModalType.SampleModal,
            payload,
          });
        } else {
          payload();
        }
      }
    }
  }

  cancelDraw() {
    if (this.viewer.isTracking) {
      EventBus.$emit('stopTracking');
    }

    const { sampleModal } = this.viewer.$refs;
    const topModal = getTopModal(this.viewer.modalStack);
    if (topModal?.type !== StackableModalType.SampleModal) {
      sampleModal.clear();
    }

    const im = this.map.getInteractionManager();

    if (this.viewer.isEditing) {
      const allEditStates = im.getAllEditStates();
      const [layerUid] = Object.keys(allEditStates);
      const [{ sampleId, layerModelId }] = allEditStates[layerUid];

      if (sampleId) {
        const feature = im.layerManager.findSampleFeatureById(sampleId);
        im.layerManager.showFeature(feature);
      } else if (layerModelId) {
        const layer = im.layerManager.findLayerByModelId(layerModelId);
        const layerModel = this.map.findLayerModelById(layerModelId);

        // Restore the geometry
        const feature = layer.getFirstFeature();
        const [originalFeature] = this.map.fromGeoJSON(layerModel.geojson);
        feature.setGeometry(originalFeature.getGeometry());

        // Restore points of interest
        const lopSample = this.map.findLopSampleByLayerModelId(layerModelId);
        const { originalPointsOfInterest } = im.getData();
        if (lopSample) {
          this.map.updateSample({
            id: lopSample.id,
            points_of_interest: originalPointsOfInterest,
          });
        }
      }
    }

    im.endSession();

    this.viewer.drawingType = undefined;
    this.viewer.isDuplicating = false;
    this.viewer.isEditing = false;
    this.viewer.isMoving = false;
    this.viewer.isDrawing = false;
    this.viewer.isPoiEditingOn = false;
    this.viewer.isTrackingAvailable = false;
    sampleModal.isAssigningShape = false;

    if (!this.viewer.isModalStackEmpty) {
      this.viewer.openSampleModal();
    }
  }

  openDraw() {
    const { sampleDataToModify } = this.viewer;
    if (!sampleDataToModify) {
      EventBus.$emit('setSampleDataToModify', {
        geojson: {
          properties: {
            type: this.viewer.drawingType,
          },
        },
      });
    }
    this.viewer.openSampleModal();
  }

  closeDraw() {
    this.viewer.closeSampleModal();
  }

  async startDrawMarker(options = {}) {
    const {
      sampleGroup,
      tabId,
      title,
      labelPosition,
      iconRotation,
      iconOpacity,
      iconOpacityOverride,
    } = options;

    this.viewer.templateTabToSelect = tabId;
    this.viewer.drawingType = DrawingType.Point;
    this.viewer.isMoving = true;

    let icon;
    let color;
    if (sampleGroup) {
      const markerIdentifierParts = parseMarkerIdentifier(
        sampleGroup.marker_identifier
      );
      icon = markerIdentifierParts.icon;
      color = markerIdentifierParts.color;
    } else if (tabId) {
      const tab = this.viewer.templateTabs.find((tab) => tab.id === tabId);
      icon = tab.point_icon;
      color = tab.drawing_colour;
    }

    const im = this.map.getInteractionManager();
    im.beginSession();
    const draw = im.requestDraw({
      layerType: LayerType.SAMPLE,
      icon,
      color,
      title,
      labelPosition,
      iconRotation,
      iconOpacity,
      iconOpacityOverride,
    });

    const { isPlacedAtCenter = false } = options;
    let { position } = options;

    if (!position && isPlacedAtCenter) {
      const { longitude, latitude } = this.map.getCenterLonLat();
      position = { lat: latitude, lng: longitude };
    }

    if (position) {
      const coord = this.map.fromLegacyLatLng(position);
      draw.addPoint(coord, true);
      im.endDraw();
    }
  }

  async startNonSpatial(param) {
    this.viewer.startNonSpatial(param);
  }

  startDrawPoly(options = {}) {
    const { drawingType, tabId, isPlainLayer } = options;

    this.viewer.templateTabToSelect = tabId;
    this.viewer.drawingType = drawingType;
    this.viewer.isDrawing = false;

    let color = Color.Black;
    let fillStyle = 0;
    let outlineStyle = 0;

    if (tabId) {
      const tab = this.viewer.templateTabs.find((tab) => tab.id === tabId);
      color = tab.drawing_colour ?? Color.Black;
      const drawingProperties = tab.drawing_properties ?? {
        fillStyle: 0,
        outlineStyle: 0,
      };
      fillStyle = drawingProperties.fillStyle;
      outlineStyle = drawingProperties.outlineStyle;
    }

    const im = this.map.getInteractionManager();
    im.beginSession({ color, fillStyle, outlineStyle, isPlainLayer });

    EventBus.$emit('startDrawPolyWithLayerType', drawingType);
  }

  startDrawPolyWithLayerType(layerType) {
    this.viewer.isDrawing = true;

    if (
      [LayerType.POLYLINE, LayerType.ARROW, LayerType.POLYGON].includes(
        layerType
      )
    ) {
      this.viewer.isTrackingAvailable = true;
    }

    const im = this.map.getInteractionManager();
    im.requestDraw({
      layerType,
      ...im.getData(),
    });
    im.once('post-feature-created', (event) => {
      if (this.viewer.isTracking) {
        EventBus.$emit('stopTracking');
      }
      this.viewer.isTrackingAvailable = false;
    });
  }

  openTrackingActivityModal() {
    this.viewer.showTrackingActivityModal = true;
  }
  closeTrackingActivityModal() {
    this.viewer.showTrackingActivityModal = false;
  }
  startTracking() {
    this.trackingActivity.start();
  }
  stopTracking() {
    this.trackingActivity.stop();
  }

  gotoMyLocation() {
    const lonLat = this.map.getCurrentLocationLonLat();

    if (lonLat) {
      this.map.animateView(lonLat);
    } else {
      this.viewer.$toastStore.error(
        'Could not get your location, please make sure you have Location services enabled on your device.'
      );
    }
  }

  backToAddress() {
    const {
      project: { longitude, latitude },
    } = this.viewer;

    this.map.animateView({ longitude, latitude }, DEFAULT_ZOOM);
  }

  showLayerStylingOffcanvas(layerModelId) {
    const { layerStylingOffcanvas } = this.viewer.$refs;
    if (!layerStylingOffcanvas) {
      throw 'Invalid state: the layer styling offcanvas is null';
    }

    layerStylingOffcanvas.show(layerModelId);
  }

  togglePoiEditing(togglePoi = false) {
    this.viewer.isPoiEditingOn = togglePoi || !this.viewer.isPoiEditingOn;
  }

  editPlainShape(layerModelId) {
    if (this.viewer.isEditing || this.viewer.isMoving) {
      return;
    }

    this.viewer.isEditing = true;

    const im = this.map.getInteractionManager();
    const layer = im.layerManager.findLayerByModelId(layerModelId);
    const feature = layer.getFirstFeature();
    im.beginSession({
      layerModelId,
    });
    const edit = im.requestEdit(feature, false);
    edit.selectFeature(feature);

    const layerModel = this.map.findLayerModelById(layerModelId);
    if (layerModel.geojson.properties.type === LayerType.HEDGE) {
      // Needs re-rendering to get background and outline.
      feature.changed();
    }
  }

  async duplicatePlainShape(layerModelId) {
    this.viewer.isEditing = true;

    const figure = this.map.getSelectedFigure();
    const layerModel = this.map.findLayerModelById(layerModelId);
    const duplicate = duplicateLayerModel(layerModel);
    await this.map.addLayerModel(figure, duplicate);

    const im = this.map.getInteractionManager();
    const duplicateLayer = im.layerManager.findLayerByModelId(duplicate.id);
    const feature = duplicateLayer.getFirstFeature();
    im.beginSession({
      layerModelId: duplicate.id,
    });
    const edit = im.requestEdit(feature, false);
    edit.selectFeature(feature);
  }

  async deletePlainShapeConfirmed() {
    const { payload: layerModelIdToDelete } = getTopModal(
      this.viewer.modalStack
    );
    let shouldPopSampleModal = true;
    const { deletePlainShape } = useMapsApi();
    try {
      await deletePlainShape(layerModelIdToDelete);
      this.map.removeLayerModel(layerModelIdToDelete);
    } catch (e) {
      const layerModelToDelete =
        this.map.findLayerModelById(layerModelIdToDelete);
      this.viewer.$toastStore.error(
        `The layer ${getLayerTitle(layerModelToDelete)} failed to be deleted.`
      );
      shouldPopSampleModal = false;
      console.error(e);
    } finally {
      // Pop the DeletePlainShapeModal
      this.viewer.popFromModalStack();
      const topModal = getTopModal(this.viewer.modalStack);
      if (shouldPopSampleModal && checkIsSampleModal(topModal)) {
        this.viewer.popFromModalStack();
      }
    }
  }

  setTemplateToSelect(value) {
    this.viewer.templateTabToSelect = value;
  }
}

export default ActionEvents;
