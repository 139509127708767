<script setup lang="ts">
import Navbar from '@/js/modules/navigation/index.vue';
import Toasts from '@component-library/components/Toasts.vue';
import { onMounted, computed, onBeforeMount, ref, provide } from 'vue';
import { useRoute } from 'vue-router';
import useChatProvider from '@component-library/chat-service';
import ConfirmModal from '@component-library/components/ConfirmModal.vue';
import { useDialogStore } from '@component-library/store/dialog';
import useAuth from '@component-library/composables/useAuth';
import { useProjectStore } from '@component-library/store/project';
import useLegacyStore from '@component-library/composables/useLegacyStore';
import SubscriptionsModal from '@component-library/components/SubscriptionsModal.vue';
import { useNavigationStore } from '@component-library/store/navigation';

const auth = useAuth();
const chatProvider = useChatProvider();
const dialogStore = useDialogStore();
const projectStore = useProjectStore();
const legacyStore = useLegacyStore();
const navigationStore = useNavigationStore();
const route = useRoute();
projectStore.setLegacyStore(legacyStore);

const deferredInstallPrompt = ref<Event | null>(null);
provide('deferredInstallPrompt', deferredInstallPrompt);

const isGatherCollection = computed(() => {
  return (route as any).currentRoute?.path.endsWith('/map/');
});

onBeforeMount(() => {
  // Clear login if handover is about to happen
  if (route.path.includes('/handover/') && route.params.access_token) {
    auth.clear();
  }

  window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    deferredInstallPrompt.value = e;
  });
});

onMounted(chatProvider.loadScript);
</script>

<template>
  <div class="app w-100">
    <Toasts />
    <Navbar
      v-if="auth.check() && auth.ready() && !navigationStore.loadingLocalState"
    />

    <div
      v-if="
        !navigationStore.loadingLocalState || route.path.includes('handover/')
      "
      class="main-header"
      :class="isGatherCollection ? 'overflow-hidden' : ''"
    >
      <RouterView />
    </div>

    <ConfirmModal
      v-if="dialogStore.showModal"
      :show="dialogStore.showModal"
      :title="dialogStore.title"
      :message="dialogStore.message"
      :danger="dialogStore.danger"
      :options="dialogStore.options"
      @confirm="dialogStore.accepted"
      @cancel="dialogStore.rejected"
    />
    <SubscriptionsModal
      v-if="auth.check() && !navigationStore.loadingLocalState"
    />
  </div>
</template>
