import arcgisPbfDecode from 'arcgis-pbf-parser';
import { Feature, Map } from 'ol';
import { getHeight, getIntersection, getWidth, isEmpty } from 'ol/extent';
import { createStyleFunctionFromUrl } from 'ol-esri-style';
import { EsriJSON, GeoJSON } from 'ol/format';
import { Vector as VectorLayer } from 'ol/layer';
import { tile as tileStrategy } from 'ol/loadingstrategy';
import { Vector as VectorSource } from 'ol/source';
import { createXYZ } from 'ol/tilegrid';
import { Code, throwError } from '../../../common/error';
import { extentToBounds } from '../../../common/extent';
import { createRequestOptions, proxify } from '../../../common/network';
import { getLayoutZoom } from '../../../measurement/layout';
import { scaleToResolution } from '../../../measurement/scale';
import type { LayerUsage } from '../../constants';
import { LayerType } from '../../types';
import { createLayerProperties, getVisibleExtent } from '../../utils';
import enableLoadingEvents from '../enableLoadingEvents';
import type {
  FeatureServerFolderLayerModel,
  FeatureServerItemLayerModel,
  FeatureServerLayer,
} from './types';

declare const axios: any;

const WKID = 4326;

function calculateMaxAllowableOffset(extent, size) {
  const xResolution = getWidth(extent) / size;
  const yResolution = getHeight(extent) / size;
  return Math.max(xResolution, yResolution);
}

export default function createFeatureServerLayer(
  map: Map,
  folderModel: FeatureServerFolderLayerModel,
  itemModel: FeatureServerItemLayerModel,
  layerType: LayerType = LayerType.BASEMAP_SERVICE,
  layerUsage?: LayerUsage
): FeatureServerLayer {
  const { attributions, token, shouldUseCorsProxy } =
    folderModel.geojson.properties;
  const { minScale, maxScale, pbfSupported } = itemModel.geojson.properties;
  const projection = map.getView().getProjection();
  const tileSize = 512;

  let { url } = folderModel.geojson.properties;
  url = `${url}/${itemModel.geojson.properties.index}`;
  if (shouldUseCorsProxy) {
    url = proxify(url);
  }

  const visibleExtent = getVisibleExtent(folderModel, projection);

  const layerSource = new VectorSource({
    attributions,
    loader: function (extent, resolution, projection, onLoad, onError) {
      const intersection = getIntersection(extent, visibleExtent);
      if (isEmpty(intersection)) {
        onLoad!([]);
        return;
      }

      // @ts-expect-error - @Matt
      const { _southWest, _northEast } = extentToBounds(
        intersection,
        projection
      );
      const extentParam = {
        spatialReference: { wkid: WKID },
        xmin: _southWest.lng,
        ymin: _southWest.lat,
        xmax: _northEast.lng,
        ymax: _northEast.lat,
      };
      let params: Record<string, string> = {
        f: pbfSupported ? 'pbf' : 'json',
        spatialRel: 'esriSpatialRelIntersects',
        geometry: JSON.stringify(extentParam),
        geometryType: 'esriGeometryEnvelope',
        inSR: String(WKID),
        outFields: '*',
        outSR: String(WKID),
        resultType: 'tile',
      };
      if (token) {
        params = {
          ...params,
          token,
        };
      }

      if (pbfSupported) {
        const maxAllowableOffset = calculateMaxAllowableOffset(
          [
            extentParam.xmin,
            extentParam.ymin,
            extentParam.xmax,
            extentParam.ymax,
          ],
          tileSize
        );
        params = {
          ...params,
          // @ts-expect-error - The quantizationParameters property is not exposed.
          maxAllowableOffset,
          quantizationParameters: JSON.stringify({
            extent: extentParam,
            mode: 'view',
            origionPosition: 'upperLeft',
          }),
        };
      }

      const queryParams = new URLSearchParams(params);
      const urlWithParams = url + '/query/?' + queryParams.toString();
      axios
        .get(urlWithParams, {
          ...createRequestOptions(),
          responseType: pbfSupported ? 'arraybuffer' : 'json',
        })
        .then(({ data }) => {
          let features: Feature<any>[] = [];
          if (pbfSupported) {
            const { featureCollection } = arcgisPbfDecode(data);
            const featureFormat = new GeoJSON({
              featureProjection: projection,
            });

            features = featureFormat.readFeatures(featureCollection);
          } else {
            const featureFormat = new EsriJSON();
            features = featureFormat.readFeatures(data, {
              featureProjection: projection,
            });
          }
          if (features.length > 0) {
            layerSource.addFeatures(features);
          }
          onLoad!(features);
        })
        .catch(() => {
          onError!();
        });
    },
    strategy: tileStrategy(
      createXYZ({
        extent: visibleExtent,
        tileSize,
        maxResolution: map.getView().getMaxResolution(),
        maxZoom: map.getView().getMaxZoom(),
      })
    ),
  });

  const center = map.getView().getCenter()!;
  const minResolution =
    maxScale > 0 ? scaleToResolution(projection, maxScale, center) : undefined;
  const maxResolution =
    minScale > 0 ? scaleToResolution(projection, minScale, center) : undefined;
  const layer = new VectorLayer({
    source: layerSource,
    extent: visibleExtent,
    minResolution,
    maxResolution,
    properties: createLayerProperties(itemModel.id, layerType, layerUsage),
  }) as FeatureServerLayer;

  // @ts-expect-error getFeatures appears to be Geometry[] but methods suggest it should be Feature[]?
  layer.getFirstFeature = function () {
    return this.getSource()!.getFeatures()[0];
  };

  layer.checkHasFeature = function (feature) {
    // @ts-expect-error feature appears to be Geometry but methods suggest it should be Feature?
    return this.getSource()!.hasFeature(feature);
  };

  layer.toGeoJSON = function () {
    throwError(Code.MethodNotImplemented, 'toGeoJSON');
  };

  layer.getBounds = function () {
    throwError(Code.MethodNotImplemented, 'getBounds');
  };

  layer.refresh = function () { };

  enableLoadingEvents(layer);

  createStyleFunctionFromUrl(url, projection, token, () =>
    getLayoutZoom(map)
  ).then((styleFunction) => {
    layer.setStyle(styleFunction);
  });

  return layer;
}
