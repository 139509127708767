<script setup>
import { nextTick, ref, watch } from 'vue';

const props = defineProps({
  toRight: Boolean,
  onTop: Boolean,
  onRight: Boolean,
  onLeft: Boolean,
  customWidth: {
    type: String,
    default: 'auto',
  },
  customStyle: {
    type: Object,
    default: () => ({}),
  },
  forceOpen: {
    type: Boolean,
    default: false,
  },
  padding: {
    type: String,
    default: 'px-3',
  },
  autoDetermineDirectionV: {
    type: Boolean,
    default: false,
  },
  autoDetermineDirectionH: {
    type: Boolean,
    default: false,
  },
  blockClose: {
    type: Boolean,
    default: false,
  },
});

const dropdownMenuRef = ref();
const toggled = ref(false);
const showOnTop = ref(props.onTop);
const showToRight = ref(props.toRight);
const shouldBlockClose = ref(props.blockClose);

const emit = defineEmits(['toggled']);

watch(
  () => props.forceOpen,
  (val) => {
    if (!val) return;

    toggled.value = true;
    emit('toggled', true);
  }
);

// Sometimes v-click-outside will fire as blockClose changes, prevents this
watch(
  () => props.blockClose,
  (val) => {
    setTimeout(() => {
      shouldBlockClose.value = val;
    }, 300);
  }
);

const toggle = async () => {
  toggled.value = !toggled.value;
  emit('toggled', toggled.value);

  if (toggled.value) {
    positionDropdown();
  }
};

const close = () => {
  if (!toggled.value || shouldBlockClose.value) {
    return;
  }

  toggle();
};

defineExpose({ toggle, toggled, close });

const positionDropdown = async () => {
  if (props.autoDetermineDirectionV) {
    showOnTop.value = false;
  }

  if (props.autoDetermineDirectionH) {
    showToRight.value = false;
  }

  await nextTick();

  const rect = dropdownMenuRef.value?.getBoundingClientRect();
  if (!rect) {
    return;
  }

  // Check bottom vs. top
  if (props.autoDetermineDirectionV) {
    const exceedsBottom = rect.bottom > window.innerHeight;
    showOnTop.value = exceedsBottom;
  }

  // Check right vs. left
  if (props.autoDetermineDirectionH) {
    const exceedsRight = rect.right > window.innerWidth;
    showToRight.value = exceedsRight;
  }
};
</script>

<template>
  <div v-click-outside="close" class="dropdown" :class="{ toggled: toggled }">
    <slot
      name="button"
      :toggle="() => toggle()"
      :toggled="toggled"
      :close="() => close()"
      :class="{ dropup: showOnTop }"
      @click="toggle"
    />

    <Transition name="dropdown">
      <div
        v-if="toggled"
        ref="dropdownMenuRef"
        class="dropdown-menu mt-1"
        :class="[
          {
            show: toggled,
            'management__dropdown dropdown-menu-end': showToRight,
            top: showOnTop,
            onRight: onRight,
            onLeft: onLeft,
          },
          padding,
        ]"
        :style="{ ...{ width: customWidth }, ...customStyle }"
      >
        <slot name="items" :toggled="toggled" :close="() => toggle()" />
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.dropdown-menu {
  z-index: 20003;
}

@media only screen and (max-width: 576px) {
  .dropdown-menu {
    width: 100% !important;
    margin: 0px;
  }
}
</style>
