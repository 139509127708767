<template>
  <div
    :class="[
      {
        focus: focused,
        'border-danger': invalid,
      },
      'tag-input',
    ]"
    @click="selectInput"
    @dragover.prevent
    @dragenter.prevent
  >
    <Tag
      v-for="(t, tIndex) in tags"
      :key="`tag-${tIndex}`"
      :tag="t"
      :index="tIndex"
      :fields="fields"
      :checkHasMissingField="checkHasMissingField"
      @moveTag="handleMoveTag"
      @removeTag="(index) => removeTag(index)"
    >
      <template v-if="t.type === 'custom_function'">
        <CustomFunction
          :value="findCustomFunctionById(t.value.id)"
          :argGroups="t.value.argGroups"
          :fields="fields"
          :checkIsParamGroupSelected="
            (paramGroupIndex) =>
              checkIsParamGroupSelected(tIndex, paramGroupIndex)
          "
          @paramGroupSelect="handleParamGroupSelect(tIndex, $event)"
          @argDelete="handleArgDelete(tIndex, $event)"
        />
      </template>
    </Tag>

    <input
      ref="input"
      v-model.trim="tagInput"
      type="text"
      placeholder="Add a text value here. For other types of values please use the Add a Value tab."
      :class="[
        'tag-input__text',
        {
          empty: tags.length == 0,
        },
      ]"
      @keydown.enter="handleEnter"
      @keydown.delete="handleDelete"
      @focus="() => (focused = true)"
      @blur="() => (focused = false)"
    />
  </div>
</template>

<script>
import { findCustomFunctionById } from '@component-library/business-logic/expression';
import * as cl_bm from '@component-library/business-model';
import CustomFunction from './CustomFunction.vue';
import Tag from './Tag.vue';

export default {
  name: 'TagInput',
  components: {
    Tag,
    CustomFunction,
  },
  props: {
    tags: Array,
    fields: Array,
    invalid: Boolean,
    checkHasMissingField: Function,
    selectedParamGroupPosition: Object,
  },
  emits: ['moveTag', 'removeTag', 'paramGroupSelect', 'add', 'argDelete'],
  data: () => ({
    focused: false,
    tagInput: null,
    draggingOverIndex: null,
  }),
  methods: {
    removeTag(index) {
      this.$emit('removeTag', index);
    },
    selectInput() {
      this.$refs['input'].focus();
      this.$emit('paramGroupSelect', {
        tagIndex: null,
        paramGroupIndex: null,
      });
    },
    findCustomFunctionById(id) {
      return findCustomFunctionById(id);
    },
    checkIsParamGroupSelected(tagIndex, paramGroupIndex) {
      return (
        this.selectedParamGroupPosition.tagIndex === tagIndex &&
        this.selectedParamGroupPosition.paramGroupIndex === paramGroupIndex
      );
    },
    handleEnter() {
      const { tagInput } = this;

      if (!tagInput) {
        return;
      }

      this.$emit('add', {
        type: cl_bm.expression.INPUT_TYPE_VALUE,
        value: tagInput,
        valueType: cl_bm.expression.VALUE_TYPE_TEXT,
      });
      this.tagInput = null;
    },
    handleDelete(event) {
      if (event.target.value.length === 0) {
        this.removeTag(this.tags.length - 1);
      }
    },
    handleParamGroupSelect(tagIndex, paramGroupIndex) {
      this.$emit('paramGroupSelect', {
        tagIndex,
        paramGroupIndex,
      });
    },
    handleArgDelete(tagIndex, { paramGroupIndex, argIndex }) {
      this.$emit('argDelete', {
        tagIndex,
        paramGroupIndex,
        argIndex,
      });
    },
    handleMoveTag(event) {
      this.$emit('moveTag', event);
    },
  },
};
</script>

<style scoped>
.tag-input {
  width: 100%;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.tag-input.focus {
  color: #495057;
  background-color: #fff;
  border-color: #71dd8a;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.tag-input__text {
  border: none;
  outline: none;
  background: none;
  margin: 2px;
  width: 100%;
  color: #495057;
}

.tag-input__text.empty {
  margin: 0px;
  padding: 0px;
}
</style>
