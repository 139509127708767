import { captureException } from '@component-library/sentry';
import { Feature, Map, getUid } from 'ol';
import { Point } from 'ol/geom';
import { Vector as VectorLayer } from 'ol/layer';
import { bbox } from 'ol/loadingstrategy';
import { Vector as VectorSource } from 'ol/source';
import { fromLonLat, toLonLat } from '../../common/coordinate';
import { extentToBounds, transformExtent } from '../../common/extent';
import { toGeoJSON as _toGeoJSON } from '../../common/geojson';
import { getStoreApi } from '../../common/store-api';
import { applyLayoutZoomToWrite } from '../../measurement/layout';
import type { Size } from '../../measurement/types';
import type { LayerUsage } from '../constants';
import { LayerType } from '../types';
import { checkIsTempLayerModel, createLayerProperties } from '../utils';
import createSampleStyleFunction from './createSampleStyleFunction';
import type { Sample, SampleGroup, SampleLayer } from './types';
import {
  DEFAULT_LABEL_POSITION,
  checkIsFilteredOutBySampleGroup,
  checkIsGlued,
  checkIsRenderableNonSpatialSample,
  getLabelOffsets,
  getLabelPosition,
  getPosition,
  getSampleLocation,
} from './utils';

export default function createSampleLayer(
  map: Map,
  model: SampleGroup,
  layerUsage?: LayerUsage
): SampleLayer {
  const storeApi = getStoreApi(map);
  const options = {
    source: new VectorSource<Feature<Point>>({
      loader(extent, resolution, projection, success, failure) {
        // The model could have been changed.
        model = storeApi.findLayerModelById(model.id) as SampleGroup;

        // Skip loading samples if the layer model is a temperary one.
        if (checkIsTempLayerModel(model)) {
          success!([]);
          return;
        }

        const extentInWgs84 = transformExtent(extent, projection, 'EPSG:4326');
        const self = this as VectorSource<Feature<Point>>;
        storeApi
          .loadSamples(model.id, extentInWgs84)
          .then(async (samples) => {
            const features: Feature<Point>[] = [];
            for (const sample of samples) {
              if (sample.sub_folder) {
                const sampleGroup = storeApi.getSampleLayerModel(
                  sample
                ) as SampleGroup;
                if (
                  sampleGroup.hidden_sub_folders.includes(sample.sub_folder)
                ) {
                  continue;
                }
              }

              if (self.getFeatureById(sample.id)) {
                continue;
              }

              if (checkIsRenderableNonSpatialSample(sample)) {
                try {
                  await storeApi.findSampleByIdAsync(
                    sample.linked_spatial_sample_id!
                  );
                } catch (e) {
                  console.warn(
                    `The linked spatial sample with id ${sample.linked_spatial_sample_id} of the renderable non-spatial sample with id ${sample.id} was not found.`,
                    e
                  );
                  continue;
                }
              }
              const { longitude, latitude } = getSampleLocation(map, sample);
              const feature = new Feature(
                new Point(
                  fromLonLat(
                    { longitude: longitude!, latitude: latitude! },
                    projection
                  )
                )
              );
              feature.setId(sample.id);
              feature.set('layerUid', getUid(layer));
              feature.set('isHidden', !layer.checkIsSampleVisible(sample));
              features.push(feature);
            }
            self.addFeatures(features);
            success!(features);
          })
          .catch((e) => {
            console.error(e);
            captureException(e);
            self.removeLoadedExtent(extent);
            if (typeof failure === 'function') {
              failure();
            }
          });
      },
      strategy: bbox,
    }),
    properties: createLayerProperties(model.id, LayerType.SAMPLE, layerUsage),
  };
  const layer = new VectorLayer(options) as SampleLayer;
  layer.setStyle(createSampleStyleFunction(map, layer));

  layer.getFirstFeature = function () {
    return this.getSource()!.getFeatures()[0];
  };

  layer.checkHasFeature = function (feature) {
    return this.getSource()!.hasFeature(feature as Feature<Point>);
  };

  layer.checkIsSampleVisible = function (sample: Sample) {
    return !checkIsFilteredOutBySampleGroup(map, sample);
  };

  layer.toGeoJSON = function () {
    const features = this.getSource()!.getFeatures() as Feature<Point>[];
    features.forEach((feature) =>
      feature.setProperties({ ...this.getProperties() })
    );
    return _toGeoJSON(map, features);
  };

  layer.getBounds = function (padding = 0) {
    const extent = this.getSource()!.getExtent();
    return extentToBounds(extent, map.getView().getProjection(), padding);
  };

  layer.refresh = function () {
    layer.getSource()!.refresh();
  };

  layer.getPosition = (sample) => {
    return getPosition(map, sample);
  };

  layer.setPosition = (sample, position) => {
    if (storeApi.checkIsRenderableNonSpatialSampleGroup(model.id)) {
      return;
    }

    const feature = layer.getSource()!.getFeatureById(sample.id);
    if (!feature) {
      throw new Error(`The sample with id ${sample.id} was not found.`);
    }
    feature.getGeometry()!.setCoordinates(position);
    const { longitude, latitude } = toLonLat(
      position,
      map.getView().getProjection()
    );
    sample.longitude = longitude;
    sample.latitude = latitude;
  };

  layer.getLabelPosition = (sample) => {
    return getLabelPosition(map, sample);
  };

  layer.setLabelPosition = (sample, labelPosition) => {
    const position = layer.getPosition(sample)!;
    if (checkIsGlued(map, { position, labelPosition })) {
      sample.label_position = DEFAULT_LABEL_POSITION;
      return;
    }

    const offsets = applyLayoutZoomToWrite<Size>(
      map,
      () => {
        const pixel = map.getPixelFromCoordinate(position);
        const labelPixel = map.getPixelFromCoordinate(labelPosition);
        return [labelPixel[0] - pixel[0], labelPixel[1] - pixel[1]];
      },
      (offsets) => {
        return offsets.map((o) => Math.round(o)) as Size;
      }
    )();
    sample.label_position = {
      offsetX: offsets[0],
      offsetY: offsets[1],
    };
  };

  layer.getLabelOffsets = (sample) => {
    return getLabelOffsets(map, sample);
  };

  layer.refresh();

  return layer;
}
