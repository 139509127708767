<script lang="ts" setup>
import { ShareGroup } from '@component-library/gather';

defineProps<{
  shareGroups: ShareGroup[];
  type: String;
}>();
const emit = defineEmits<{
  (event: 'loadShareGroups', type: string): void;
}>();
</script>

<template>
  <ul class="nav nav-pills nav-fill mb-3">
    <li class="nav-item" @click.prevent="emit('loadShareGroups', 'all')">
      <div
        class="nav-link p-3"
        :class="{
          'bg-dark text-white fw-bold': type === 'all',
        }"
      >
        All Apps
      </div>
    </li>
    <li class="nav-item" @click.prevent="emit('loadShareGroups', 'workflow')">
      <div
        class="nav-link p-3"
        :class="{
          'bg-dark text-white fw-bold': type === 'workflow',
        }"
      >
        Workflow Optimization
      </div>
    </li>
    <li class="nav-item" @click.prevent="emit('loadShareGroups', 'company')">
      <div
        class="nav-link p-3"
        :class="{
          'bg-dark text-white fw-bold': type === 'company',
        }"
      >
        Company Apps
      </div>
    </li>
    <li class="nav-item" @click.prevent="emit('loadShareGroups', 'user')">
      <div
        class="nav-link p-3"
        :class="{
          'bg-dark text-white fw-bold': type === 'user',
        }"
      >
        Your Apps
      </div>
    </li>
    <li class="nav-item" @click.prevent="emit('loadShareGroups', 'location')">
      <div
        class="nav-link p-3"
        :class="{
          'bg-dark text-white fw-bold': type === 'location',
        }"
      >
        Datanest Apps
      </div>
    </li>
  </ul>
</template>
