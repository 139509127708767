<script setup lang="ts">
import Modal from '@component-library/components/Modal.vue';
import useStylingRules from '@/js/composables/useStylingRules';
import store from '@/js/store';
import { findFieldByIdFromApp } from '@component-library/business-logic/app';
import type {
  StylingRuleOnGatherApp as StylingRule,
  StylingRuleDataOnGatherApp as StylingRuleData,
} from '@component-library/business-logic/mapping/styling-rule';
import { DrawingType, type App } from '@component-library/gather';
import { useToastStore } from '@component-library/store/toasts';
import { ref } from 'vue';
import ButtonSpinner from '@component-library/components/ButtonSpinner.vue';
import StylingRuleEditor from './StylingRuleEditor.vue';
import StylingRuleList from './StylingRuleList.vue';
import makeId from '@component-library/local-id.mjs';
import { checkIsValuePairEmpty } from '@component-library/business-logic/input-value';

const props = defineProps<{ app: App }>();
const emit = defineEmits(['close', 'save']);
const toastStore = useToastStore();
const { activeTempStylingRule, createShapeProperties } = useStylingRules(
  props.app
);

const isSaving = ref(false);
const isEditorVisible = ref(false);
const stylingRuleData = ref<StylingRuleData | undefined>(undefined);

function openEditor() {
  isEditorVisible.value = true;
}

function closeEditor() {
  isEditorVisible.value = false;
}

function handleClose() {
  if (isEditorVisible.value) {
    closeEditor();
  } else {
    emit('close');
  }
}

function handleSave() {
  isSaving.value = true;
  emit('save', () => {
    isSaving.value = false;
    emit('close');
  });
}

async function handleStylingRuleAdd() {
  await store.dispatch('setActiveTempStylingRuleId', undefined);
  const shapeProperties =
    props.app.drawing_type === DrawingType.Point
      ? { icon: props.app.point_icon, color: props.app.drawing_colour }
      : { color: props.app.drawing_colour };
  await store.dispatch('setShapeProperties', shapeProperties);
  openEditor();
}

async function handleStylingRuleEdit(stylingRule: StylingRule) {
  await store.dispatch('setActiveTempStylingRuleId', stylingRule.id);
  await store.dispatch(
    'setShapeProperties',
    createShapeProperties(stylingRule)
  );
  openEditor();
}

async function handleStylingRuleDelete(stylingRule: StylingRule) {
  await store.dispatch('deleteOneOfTempStylingRules', stylingRule.id);
}

function handleEditorCancel() {
  isEditorVisible.value = false;
}

async function handleEditorOK() {
  const { name, target, condition, style } = stylingRuleData.value!;
  const field = findFieldByIdFromApp(props.app, condition.fieldId)!;
  if (checkIsValuePairEmpty(condition.operand, field)) {
    toastStore.error('The operand is empty.');
    return;
  }

  if (!activeTempStylingRule.value) {
    const id = makeId();
    const newStylingRule = {
      id,
      name,
      target,
      condition,
      style,
    };
    await store.dispatch('addNewStylingRule', newStylingRule);
    await store.dispatch('setActiveTempStylingRuleId', id);
  } else {
    await store.dispatch('updateOneOfTempStylingRules', {
      id: activeTempStylingRule.value.id,
      producer: (draft) => {
        draft.name = name;
        draft.target = target;
        draft.condition = condition;
        draft.style = style;
      },
    });
  }

  closeEditor();
}
</script>

<template>
  <Modal :half="true" :shouldCloseOnShadeClick="true" @close="handleClose">
    <template #header> Styling Rules </template>

    <template #default>
      <StylingRuleList
        v-if="!isEditorVisible"
        :app="app"
        @add="handleStylingRuleAdd"
        @edit="handleStylingRuleEdit"
        @delete="handleStylingRuleDelete"
      />
      <StylingRuleEditor
        v-else
        :app="app"
        @change="(value) => (stylingRuleData = value)"
      />
    </template>

    <template #footer>
      <template v-if="!isEditorVisible">
        <button class="btn btn-outline-secondary" @click="handleClose">
          Close
        </button>
        <ButtonSpinner
          class="btn btn-primary"
          style="width: 150px"
          :isLoading="isSaving"
          @click="handleSave"
        >
          Save
        </ButtonSpinner>
      </template>
      <template v-else>
        <button class="btn btn-outline-secondary" @click="handleEditorCancel">
          Cancel
        </button>
        <button
          class="btn btn-primary"
          style="width: 150px"
          :disabled="!stylingRuleData"
          @click="handleEditorOK"
        >
          OK
        </button>
      </template>
    </template>
  </Modal>
</template>
