<template>
  <modal :half="true" @close="close">
    <template #header> App Preview </template>
    <template #default>
      <FormSection
        v-for="section of sections"
        v-if="sections"
        :key="section.id"
        :allFields="allFields"
        :section="section"
        :samples="[]"
        :inputValues="[]"
        :shouldEmit="false"
        :previewForm="true"
        :allSections="sections"
        :showAddSection="false"
        :templateTab="dataTab"
        class="modal-body"
      />
    </template>
    <template #footer>
      <ButtonSpinner
        class="btn btn-primary w-100"
        :loading="false"
        @click="close"
      >
        Close
      </ButtonSpinner>
    </template>
  </modal>
</template>

<script setup lang="ts">
import { FORM_CONTEXT_TYPE_APP_PREVIEW } from '@component-library/business-model/common';
import ButtonSpinner from '@component-library/components/ButtonSpinner.vue';
import Modal from '@component-library/components/Modal.vue';
import FormSection from '@component-library/form/FormSection.vue';
import type { App } from '@component-library/gather';
import { createFormContext } from '@component-library/utils';
import _cloneDeep from 'lodash/cloneDeep';
import { computed, provide, ref } from 'vue';

const props = defineProps<{
  dataTab: App;
}>();

const emit = defineEmits(['close']);

provide(
  'formContext',
  createFormContext(
    FORM_CONTEXT_TYPE_APP_PREVIEW,
    () => Promise.resolve(),
    () => undefined
  )
);

const sections = ref(
  props.dataTab.sections ? _cloneDeep(props.dataTab.sections) : []
);
const allFields = computed(() => {
  return sections.value.reduce((accu, s) => {
    return [...accu, ...s.template_fields];
  }, []);
});
const close = () => {
  emit('close');
};
</script>
