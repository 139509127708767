<script lang="ts" setup>
import EventBus from '../EventBus';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { Project } from '../project';

const _route = useRoute();

const props = defineProps<{
  app?: string;
  route?: string;
  title: string;
  colour: string;
  icon: string;
  tooltip?: string;
  largeCard?: boolean;
  isLightStyling?: boolean;
  project?: Project;
}>();

type GoToRoute = {
  route: string;
  app: string;
  isMiddleClick: boolean;
  project?: Project;
};

const emit = defineEmits<{
  (event: 'goToApp', data: GoToRoute): void;
}>();

const isActiveRoute = computed(() => {
  return _route.name == props.route;
});

function goto(isMiddleClick: boolean) {
  const data: GoToRoute = {
    route: props.route!,
    app: props.app!,
    isMiddleClick,
    project: props.project,
  };

  emit('goToApp', data);
  EventBus.$emit('goToApp', data);
}

const cySelector = computed(() => {
  let str = '';
  if (props.route) {
    str = props.route;
  }
  if (props.app) {
    if (str.length) {
      str += '-';
    }
    str += props.app;
  }
  str += '-route';
  return str;
});
</script>

<template>
  <div>
    <div
      class="application p-3 clickable h-100"
      :title="tooltip"
      :data-cy="cySelector"
      :class="{
        active: isActiveRoute,
        'application--light': isLightStyling,
        'application--large': largeCard,
      }"
      @click="() => goto(false)"
      @mousedown.middle="() => goto(true)"
    >
      <h3
        class="mb-0 fa-fw"
        :style="{
          color: colour,
        }"
        :class="icon"
      />

      <div
        class="d-flex flex-column content mt-1"
        :class="isLightStyling ? 'text-start' : 'text-center'"
      >
        <template v-if="largeCard">
          <h6 class="fw-medium mb-1">{{ title }}</h6>
          <span class="text-muted fw-medium">{{ tooltip }}</span>
        </template>
        <template v-else>
          <small class="text-muted fw-medium">{{ title }}</small>
        </template>

        <slot />
      </div>
    </div>
  </div>
</template>
