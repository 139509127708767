<script lang="ts" setup>
import { computed, onMounted, watch } from 'vue';
import {
  findAppByFieldId,
  findFieldByIdFromApp,
} from '../../../business-logic/app';
import type { Context } from '../../../composables/useItemsFromAppTitle';
import useItemsFromAppTitle from '../../../composables/useItemsFromAppTitle';
import type {
  App,
  GatherField,
  Item,
  RenderableNonSpatialSampleGroup,
} from '../../../gather';
import { ALL_ITEMS_OPTION_VALUE } from '../../../gather';
import { useCollectionStore } from '../../../store/collection';

const props = defineProps<{
  context: Context;
  apps: App[];
  project_id: number;
  sampleGroup: RenderableNonSpatialSampleGroup;
}>();

const emit = defineEmits<{
  (event: 'isLoadingChange', value: boolean): void;
  (
    event: 'selectedItemChange',
    value: { layerModelId: number; selectedItemId: number }
  ): void;
}>();

const collectionStore = useCollectionStore();
const linkField = computed<GatherField | undefined>(() => {
  if (props.sampleGroup.unrenderableAppLinkConfigs.length === 0) {
    return;
  }

  const [{ linkFieldId }] = props.sampleGroup.unrenderableAppLinkConfigs;
  const app = findAppByFieldId(props.apps, linkFieldId);
  if (!app) {
    return;
  }
  return findFieldByIdFromApp(app, linkFieldId);
});
const linkedApp = computed<App | undefined>(() => {
  const { template_tab_title: linkedAppTitle } = linkField.value?.options ?? {};
  if (!linkedAppTitle) {
    return;
  }
  return props.apps.find((app) => app.title === linkedAppTitle);
});
const { isLoading, items } = useItemsFromAppTitle(
  props.project_id,
  props.context,
  linkedApp.value ? () => linkedApp.value?.title || null : undefined
);
const selectedItemId = computed<number>({
  get() {
    return props.sampleGroup.filteredUnrenderableItemIds[0];
  },
  set(newValue) {
    emit('selectedItemChange', {
      layerModelId: props.sampleGroup.layerModelId,
      selectedItemId: newValue,
    });
  },
});

function getItemTitle(item: Item): string {
  const { custom_title: customTitle, lab_title: labTitle } = item;
  return (customTitle || labTitle) ?? '';
}

watch(
  isLoading,
  (newValue) => {
    emit('isLoadingChange', newValue);
  },
  { immediate: true }
);

onMounted(() => {
  if (selectedItemId.value === undefined) {
    selectedItemId.value = ALL_ITEMS_OPTION_VALUE;
  }
});
</script>

<template>
  <div>
    <small class="d-block mb-1 text-start text-wrap">
      {{ linkedApp?.title }}
    </small>
    <select
      v-model.number="selectedItemId"
      class="form-control form-control-sm"
      :disabled="collectionStore.isBusy"
    >
      <option :value="ALL_ITEMS_OPTION_VALUE">All items</option>
      <option v-for="item in items" :key="item.id" :value="item.id">
        {{ getItemTitle(item) }}
      </option>
    </select>
  </div>
</template>
