<script setup lang="ts">
import { checkIsInputValueEmpty } from '@component-library/business-logic/input-value';
import { FieldTypeIds } from '@component-library/fields';
import type { InputValue, Section } from '@component-library/gather';
import { onBeforeUnmount, onMounted, ref } from 'vue';

const props = defineProps<{ sections: Section[]; inputValues: InputValue[] }>();
const emit = defineEmits<{
  (event: 'sizeChange', value: { width; height }): void;
}>();

const isExpanded = ref(false);
const formNavigation = ref<HTMLDivElement | null>(null);
const isVisibleBySectionId = ref<Record<number, boolean>>({});

function getSectionLength(sectionId) {
  const values = props.inputValues
    .filter((iv) => iv.template_section_id == sectionId)
    .map((iv) => iv.template_section_index);

  return values.length > 0 ? Math.max(...values) + 1 : 1;
}

function toggleIsExpanded() {
  isExpanded.value = !isExpanded.value;
}

function toggleIsVisible(section: Section) {
  isVisibleBySectionId.value[section.id] =
    !isVisibleBySectionId.value[section.id];
}

function goToSection(section: Section, n: number) {
  isVisibleBySectionId.value[section.id] = false;
  isExpanded.value = false;

  const sectionTargetId = `section-${section.id}-${n}`;
  const sectionTarget = document.getElementById(sectionTargetId);
  if (sectionTarget) {
    sectionTarget.scrollIntoView(true);
  }
  const sampleModal = document.getElementById('sampleModal');
  if (sampleModal) {
    sampleModal.scrollTop -= 51;
  }
}

function getPrimaryFieldTitle(section, index) {
  const base64regex =
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  const inputValue = props.inputValues.find(
    (iv) =>
      iv.template_section_id == section.id &&
      iv.template_field_id ==
        (section.primary_field_id ||
          section.template_fields.find(
            (f) => f.field_type_id !== FieldTypeIds.COPY_DATA_LINK
          )?.id) &&
      iv.template_section_index == index - 1
  );
  if (
    inputValue?.value instanceof Blob ||
    (typeof inputValue?.value === 'string' &&
      base64regex.test(inputValue.value))
  ) {
    return inputValue.value2 || `#${index}`;
  }
  if (Array.isArray(inputValue?.value)) {
    const v1 = inputValue.value[0];
    if (typeof v1 === 'object') {
      if ('name' in v1) {
        return v1.name || `#${index}`;
      }
      return `#${index}`;
    }
    return inputValue.value[0] || `#${index}`;
  }
  return inputValue?.value || `#${index}`;
}

function getSectionProgress(section: Section): number {
  const fields = section.template_fields ?? [];
  const sl = getSectionLength(section.id);
  const all = fields.length * sl;
  const finished = props.inputValues.filter((iv) => {
    const field = fields.find((f) => f.id === iv.template_field_id);
    if (!field) {
      return false;
    }
    return (
      iv.template_section_id === section.id &&
      !checkIsInputValueEmpty(iv, field)
    );
  }).length;

  return (finished / all) * 100;
}

let resizeObserver;

onMounted(() => {
  resizeObserver = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.target === formNavigation.value) {
        const [{ inlineSize: width, blockSize: height }] = entry.borderBoxSize;
        emit('sizeChange', { width, height });
      }
    });
  });
  resizeObserver.observe(formNavigation.value);
});

onBeforeUnmount(() => {
  resizeObserver.disconnect();
});
</script>

<template>
  <nav ref="formNavigation" class="navbar navbar-dark bg-extra mb-3">
    <div class="container-fluid flex-column">
      <button
        type="button"
        class="navbar-toggler d-block w-100 px-5 text-light"
        @click="toggleIsExpanded"
      >
        <i
          :class="[
            'fal',
            {
              'fa-times': isExpanded,
              'fa-bars': !isExpanded,
            },
          ]"
        />

        <span class="px-2 my-4 fs-6">Jump to</span>
      </button>

      <div
        :class="[
          {
            'd-flex': isExpanded,
            'd-none': !isExpanded,
          },
          'w-100',
        ]"
      >
        <ul class="navbar-nav flex-wrap custom-scrollbar w-100 mb-2 mb-lg-0">
          <template v-for="section in sections" :key="section.id">
            <li
              v-if="getSectionLength(section.id) === 1"
              class="nav-item w-100"
            >
              <a class="nav-link" @click.prevent="goToSection(section, 1)">
                {{ section.label }}
              </a>
              <div class="nav-item__progress">
                <div
                  class="progress__amount"
                  :style="{
                    width: getSectionProgress(section) + '%',
                  }"
                />
              </div>
            </li>
            <li v-else class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                @click.prevent="toggleIsVisible(section)"
              >
                {{ section.label }}
              </a>
              <div class="nav-item__progress">
                <div
                  class="progress__amount"
                  :style="{
                    width: getSectionProgress(section) + '%',
                  }"
                />
              </div>
              <ul
                :class="[
                  'dropdown-menu',
                  {
                    'd-block': isVisibleBySectionId[section.id],
                  },
                ]"
                style="top: 51px; left: 0"
              >
                <li
                  v-for="n in getSectionLength(section.id)"
                  :key="`${section.id}-${n}`"
                >
                  <a
                    class="dropdown-item"
                    @click.prevent="goToSection(section, n)"
                  >
                    {{ getPrimaryFieldTitle(section, n) }}
                  </a>
                </li>
              </ul>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </nav>
</template>

<style scoped>
.navbar {
  position: sticky;
  top: -1rem;
  z-index: 9999;
  width: 100%;
}

.navbar-nav .dropdown-menu {
  position: absolute;
  width: 100%;
}

.nav-item {
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
  padding: 0.5rem;
  margin-right: 0;
}

.nav-item__progress {
  position: absolute;
  left: 0;
  bottom: 6px;
  height: 4px;
  width: 100%;
  background: #ffffff50;
}

.progress__amount {
  background: #ffffff;
  height: 4px;
  transition: 0.3s ease-in-out all;
}

@media (min-width: 992px) {
  .navbar-nav {
    max-height: 250px;
    overflow: auto;
  }
}
</style>
