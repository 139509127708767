<template>
  <div v-if="hasFieldType">
    <ResponsiveOrSlideUp
      v-model="showDefaultOptions"
      class="mb-3"
      title="Field Options"
      data-cy="field-options"
      :mobileSize="mobileSize"
    >
      <template #body>
        <template v-if="disableEditingField">
          This field is locked to prevent breaking exports</template
        >
        <template v-else>
          <label
            class="form-label"
            :class="{ 'text-danger': invalidNameError }"
          >
            <InfoButton
              v-if="invalidNameError"
              class="me-2"
              :danger="true"
              backgroundColor="#FF0000"
              :info="invalidNameError"
            />
            Field Name
          </label>
          <div v-tooltip="invalidNameError" class="form-group mb-3">
            <input
              ref="fieldLabelInput"
              v-model="fieldLabel"
              type="text"
              :class="[
                'form-control w-100',
                { 'text-danger is-invalid': invalidNameError },
              ]"
              @keypress.enter="clearField"
            />
          </div>

          <FieldCaptionOptions
            v-if="isCaption"
            :value="section.template_fields[fieldIndex]"
            @input="updateField"
          />

          <div
            v-if="
              !fieldTypesNonPrimary.includes(field.field_type_id) && !isReadonly
            "
            class="form-check"
            :class="{
              'mb-2': fieldComponent !== 'unknown-field',
            }"
          >
            <input
              id="is_required"
              v-model="isRequired"
              class="form-check-input"
              type="checkbox"
            />
            <label class="form-check-label" for="is_required">
              Is this field required?
            </label>
          </div>

          <div
            v-if="isReadonlyVisible"
            class="form-check"
            :class="{
              'mb-2': fieldComponent !== 'unknown-field',
            }"
          >
            <input
              :id="readOnlyCheckboxId"
              v-model="isReadonly"
              class="form-check-input"
              type="checkbox"
            />
            <label class="form-check-label" :for="readOnlyCheckboxId">
              Is this field read only?
              <InfoButton
                class="ms-2"
                info="When checked, this field will be read-only and cannot be edited by users. This can be useful in combination with any of the following features: Auto Assign; API only fields; or Data Event conditions"
                @click.native.prevent
              />
            </label>
          </div>

          <div
            v-if="!fieldTypesNonPrimary.includes(field.field_type_id)"
            class="form-check"
            :class="{
              'mb-2': fieldComponent !== 'unknown-field',
            }"
          >
            <input
              id="primary_field_id"
              v-model="isPrimary"
              class="form-check-input"
              type="checkbox"
            />
            <label class="form-check-label" for="primary_field_id">
              Is this the primary field on this section?
            </label>
          </div>

          <div
            v-if="
              hasCopyDataLinkField &&
              FieldTypeIds.COPY_DATA_LINK !== field.field_type_id
            "
            :class="[
              'form-check',
              {
                'mb-2': fieldComponent != 'unknown-field',
              },
            ]"
          >
            <input
              id="involved_in_data_copy_field_id"
              v-model="isInvolvedInDataCopy"
              class="form-check-input"
              type="checkbox"
            />
            <label
              class="form-check-label"
              for="involved_in_data_copy_field_id"
            >
              Is this field involved in data copy?
            </label>
          </div>

          <template
            v-if="
              tab.drawing_type === 'polyline' &&
              field.field_type_id === FieldTypeIds.DROPDOWN
            "
          >
            <div class="form-check mb-2">
              <input
                id="is_start_node_field"
                v-model="isStartNodeField"
                class="form-check-input"
                type="checkbox"
              />
              <label class="form-check-label" for="is_start_node_field">
                Is this the start node field?
              </label>
            </div>

            <div class="form-check mb-2">
              <input
                id="is_end_node_field"
                v-model="isEndNodeField"
                class="form-check-input"
                type="checkbox"
              />
              <label class="form-check-label" for="is_end_node_field">
                Is this the end node field?
              </label>
            </div>
          </template>

          <div
            v-if="FieldTypeIds.LITHOLOGY === field.field_type_id"
            class="form-check"
            :class="{
              'mb-2': fieldComponent !== 'unknown-field',
            }"
          >
            <input
              id="secondary_field_id"
              v-model="isSecondary"
              class="form-check-input"
              type="checkbox"
            />
            <label class="form-check-label" for="secondary_field_id">
              Is this the secondary field on this section?
            </label>
          </div>

          <div
            v-if="templateHasPublicForm && canBeOnPublicForm"
            class="form-check"
            :class="{
              'mb-2': fieldComponent !== 'unknown-field',
            }"
          >
            <input
              id="is_public_form"
              class="form-check-input"
              type="checkbox"
              :value="field.options.is_public_form"
              :checked="field.options.is_public_form"
              @input="
                updateOption({
                  key: 'is_public_form',
                  value: $event.target.checked,
                })
              "
            />
            <label class="form-check-label" for="is_public_form">
              Include on Public Form?
            </label>
          </div>

          <component
            :is="fieldComponent"
            :field="field"
            :value="section.template_fields[fieldIndex]"
            :section="section"
            :options="section.template_fields[fieldIndex].options"
            :tab="tab"
            @updateOptions="updateFieldOptions"
            @updateField="updateField"
            @blur="clearField"
            @update="updateOption"
            @showExpressionEditor="emit('showExpressionEditor')"
          />
        </template>
        <div class="my-2">
          <a
            href=""
            class="dropdown-toggle"
            @click.prevent="toggleAdvancedOptions"
          >
            {{ showAdvancedOptions ? 'Hide' : 'Show' }} Advanced Options
          </a>
        </div>
        <div v-if="showAdvancedOptions" class="form-group mb-3">
          <label
            class="form-label"
            :class="{ 'text-danger': invalidNameError }"
          >
            <InfoButton
              v-if="invalidNameError"
              class="me-2"
              :danger="true"
              backgroundColor="#FF0000"
              :info="invalidNameError"
            />
            System Reference
            <InfoButton
              class="ms-2"
              info="This is the system reference used in Auto Doc code snippets.<br/><b>Tip:</b> You can put a short unique name here to avoid clashes with labels."
            />
          </label>
          <div class="input-group">
            <input
              v-model="system_reference"
              v-tooltip="invalidNameError"
              type="text"
              class="form-control"
              :disabled="disableEditingField"
              :class="{ 'text-danger is-invalid': invalidNameError }"
              :placeholder="systemReferenceDefault"
              @keypress.enter="clearField"
            />
            <button
              class="btn btn-outline-secondary"
              type="button"
              @click="clipboardSystemReference"
            >
              <i class="fas fa-copy"></i>
            </button>
          </div>

          <InputCheckbox
            v-model="disableEditingField"
            :disabled="!isAdminOrImpersonating"
            label="Lock field from edits"
          />
        </div>
      </template>
    </ResponsiveOrSlideUp>

    <FieldConditionalOptions
      v-if="
        section &&
        hasMultipleFields &&
        section.template_fields[fieldIndex].options.conditions
      "
      class="mb-3"
      :field="section.template_fields[fieldIndex]"
      :modelValue="section.template_fields[fieldIndex].options"
      :mobileSize="mobileSize"
      :fields="conditionalFields"
      @update:modelValue="updateFieldOptions"
      @add="addCondition"
    />

    <FieldGroupOptions
      v-if="
        (isDropdown || isLithology) &&
        field.options.options &&
        field.options.options.length &&
        hasMultipleFields
      "
      class="mb-3"
      :options="section.template_fields[fieldIndex].options"
      :fields="conditionalFields"
      :mobileSize="mobileSize"
      @updateOptions="updateFieldOptions"
    ></FieldGroupOptions>

    <FieldDefaultOptions
      v-if="
        section &&
        (isDropdown || isLithology || isText || isCheckbox) &&
        hasMultipleFields
      "
      :field="section.template_fields[fieldIndex]"
      :options="section.template_fields[fieldIndex].options"
      :mobileSize="mobileSize"
      :fields="conditionalFields"
      @updateOptions="updateFieldOptions"
    />
  </div>
</template>

<script lang="ts" setup>
import { FieldTypeId, FieldTypeIds } from '@component-library/fields';
import slugify from '@component-library/utils/slugify';
import FieldCaptionOptions from './FieldCaptionOptions.vue';
import FieldConditionalOptions from './FieldConditionalOptions.vue';
import FieldDefaultOptions from './FieldDefaultOptions.vue';
import FieldGroupOptions from './FieldGroupOptions.vue';
import ResponsiveOrSlideUp from './ResponsiveOrSlideUp.vue';
import options from './options';
import InfoButton from '@component-library/components/InfoButton.vue';
import {
  App,
  Condition,
  GatherFieldOptions,
  getFieldReferenceError,
} from '@component-library/gather';
import InputCheckbox from '@component-library/components/InputCheckbox.vue';
import makeId from '@component-library/local-id.mjs';
import auth from '@component-library/auth';
import EventBus from '@component-library/EventBus';
import Section from '@component-library/classes/Section';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import Field from '@component-library/classes/Field';
import { useToastStore } from '@component-library/store/toasts';

const toastStore = useToastStore();
const props = defineProps<{
  section: Section;
  sectionField: Field;
  sectionIndex: number;
  fieldIndex: number;
  mobileSize: boolean;
  tab: App;
}>();
const emit = defineEmits<{
  (event: 'update:section', value: Section): void;
  (event: 'showExpressionEditor'): void;
  (
    event: 'updateField',
    value: {
      sectionIndex: number;
      fieldIndex: number;
      value: Field;
    }
  ): void;
  (
    event: 'updateFieldOptions',
    value: {
      sectionIndex: number;
      fieldIndex: number;
      options: GatherFieldOptions;
    }
  ): void;
  (
    event: 'updateTabField',
    value: { key: string; value: string | number | boolean | null | undefined }
  ): void;
  (event: 'clearField'): void;
  (
    event: 'addCondition',
    value: { sectionIndex: number; fieldIndex: number; condition: Condition }
  ): void;
}>();

const showDefaultOptions = ref(true),
  showAdvancedOptions = ref(false),
  readOnlyCheckboxId = ref(makeId());

const fieldLabelInput = ref<HTMLInputElement>();

const isAdmin = computed(() => {
  return auth.user().role == 'admin';
});
const isAdminOrImpersonating = computed(() => {
  return isAdmin.value || auth.impersonating();
});
const hasFieldType = computed(() => {
  return field.value?.field_type_id;
});
const invalidNameError = computed(() => {
  return getFieldReferenceError(field.value as any, props.section as any);
});

const fields = computed({
  get() {
    return props.section?.template_fields || [];
  },
  set(updated) {
    emit('update:section', {
      ...props.section,
      template_fields: updated,
    } as Section);
  },
});

const field = computed({
  get() {
    return props.sectionField;
  },
  set(updated) {
    emit('updateField', {
      sectionIndex: props.sectionIndex,
      fieldIndex: props.fieldIndex,
      value: { ...props.sectionField, ...updated } as Field,
    });
  },
});

const isRequired = computed({
  get() {
    return props.sectionField.is_required;
  },
  set(is_required) {
    const options = { ...(props.sectionField?.options ?? {}) };
    options.is_readonly = is_required
      ? undefined
      : props.sectionField.options.is_readonly;
    emit('updateField', {
      sectionIndex: props.sectionIndex,
      fieldIndex: props.fieldIndex,
      value: { ...props.sectionField, is_required, options } as Field,
    });
  },
});

const isReadonly = computed({
  get() {
    return props.sectionField.options.is_readonly;
  },
  set(value) {
    const options = props.sectionField?.options ?? {};
    updateFieldOptions({
      ...options,
      is_readonly: value,
      is_add_enabled: value ? undefined : options.is_add_enabled,
      has_multiple: value ? undefined : options.has_multiple,
      should_clear_selection_on_invisible: value
        ? undefined
        : options.should_clear_selection_on_invisible,

      is_richtext: value ? undefined : options.is_richtext,
    });
  },
});

const isReadonlyVisible = computed(() => {
  const { field_type_id } = props.sectionField;
  if (field_type_id === FieldTypeIds.DOCUMENT) {
    return true;
  } else if (field_type_id === FieldTypeIds.MEDIA) {
    return props.sectionField.options.type === 'video';
  }
  if (
    field_type_id &&
    [FieldTypeIds.TEXT, FieldTypeIds.DROPDOWN].includes(field_type_id as any)
  ) {
    return true;
  }

  return false;
});
const disableEditingField = computed({
  get() {
    return props.sectionField.options.disabled;
  },
  set(value) {
    updateFieldOptions({
      ...props.sectionField.options,
      disabled: value,
    });
  },
});

const isPrimary = computed({
  get() {
    return (
      props.sectionField.id != null &&
      props.sectionField.id == props.section.primary_field_id
    );
  },
  set(updated) {
    const field = props.sectionField;
    emit('update:section', {
      ...props.section,
      primary_field_id: updated ? field.id : null,
    } as Section);
  },
});

const isSecondary = computed({
  get() {
    return (
      props.sectionField.id != null &&
      props.sectionField.id == props.section.secondary_field_id
    );
  },
  set(updated) {
    const field = props.sectionField;
    emit('update:section', {
      ...props.section,
      secondary_field_id: updated ? field.id : null,
    } as Section);
  },
});

const isInvolvedInDataCopy = computed({
  get() {
    return props.sectionField.options.isInvolvedInDataCopy ?? true;
  },
  set(value) {
    emit('updateField', {
      sectionIndex: props.sectionIndex,
      fieldIndex: props.fieldIndex,
      value: {
        ...props.sectionField,
        options: {
          ...props.sectionField.options,
          isInvolvedInDataCopy: value,
        },
      } as Field,
    });
  },
});

const isStartNodeField = computed({
  get() {
    return (
      props.tab.start_node_field &&
      props.tab.start_node_field === field.value.id
    );
  },
  set(value) {
    emit('updateTabField', {
      key: 'start_node_field',
      value: value ? field.value.id : null,
    });
  },
});

const isEndNodeField = computed({
  get() {
    return (
      props.tab.end_node_field && props.tab.end_node_field === field.value.id
    );
  },
  set(value) {
    emit('updateTabField', {
      key: 'end_node_field',
      value: value ? field.value.id : null,
    });
  },
});

const conditionalFields = computed(() => {
  const field = props.sectionField;
  return props.section.template_fields.filter(
    (f) =>
      (f.id != field.id || !field.id) &&
      conditionalFieldTypeIds.value.includes(f.field_type_id as any)
  );
});
const hasMultipleFields = computed(() => {
  return fields.value.length > 1;
});
const isDropdown = computed(() => {
  return field.value.field_type_id == FieldTypeIds.DROPDOWN;
});
const isLithology = computed(() => {
  return field.value.field_type_id === FieldTypeIds.LITHOLOGY;
});
const isCaption = computed(() => {
  return field.value.field_type_id == FieldTypeIds.CAPTION;
});
const isText = computed(() => {
  return field.value.field_type_id == FieldTypeIds.TEXT;
});
const isCheckbox = computed(() => {
  return field.value.field_type_id === FieldTypeIds.CHECKBOX;
});

const fieldComponent = computed(() => {
  return options.getComponentNameFromId(field.value.field_type_id);
});

const templateHasPublicForm = computed(() => {
  return props.tab?.public_link;
});
const canBeOnPublicForm = computed(() => {
  return field.value.field_type_id !== FieldTypeIds.COPY_DATA_LINK;
});
const system_reference = computed({
  get() {
    return field.value.system_reference;
  },
  set(value) {
    updateFieldProp('system_reference', value);
  },
});

const systemReferenceDefault = computed(() => {
  if (!field.value.label) {
    return '';
  }
  return slugify(field.value.label) || '';
});
const conditionalFieldTypeIds = computed<FieldTypeId[]>(() => {
  return [
    FieldTypeIds.TEXT,
    FieldTypeIds.NUMBER,
    FieldTypeIds.DROPDOWN,
    FieldTypeIds.LITHOLOGY,
    FieldTypeIds.CHECKBOX,
    FieldTypeIds.EXPRESSION,
    FieldTypeIds.REFERENCE,
  ];
});
const fieldTypesNonPrimary = computed<FieldTypeId[]>(() => {
  return [FieldTypeIds.CAPTION, FieldTypeIds.LITHOLOGY];
});

const fieldLabel = computed({
  get() {
    return props.sectionField.label;
  },
  set(label) {
    emit('updateField', {
      sectionIndex: props.sectionIndex,
      fieldIndex: props.fieldIndex,
      value: { ...props.sectionField, label } as Field,
    });
  },
});

const hasCopyDataLinkField = computed(() => {
  return fields.value.some(
    (f) => f.field_type_id === FieldTypeIds.COPY_DATA_LINK
  );
});

function addCondition(condition) {
  emit('addCondition', {
    sectionIndex: props.sectionIndex,
    fieldIndex: props.fieldIndex,
    condition,
  });
}

function updateFieldOptions(value: GatherFieldOptions) {
  const options = { ...value } as GatherFieldOptions;
  // @ts-expect-error
  delete options.isDropdown;
  emit('updateFieldOptions', {
    sectionIndex: props.sectionIndex,
    fieldIndex: props.fieldIndex,
    options,
  });
}

function updateField(value: Partial<Field>) {
  emit('updateField', {
    sectionIndex: props.sectionIndex,
    fieldIndex: props.fieldIndex,
    value: { ...props.sectionField, ...value } as Field,
  });
}

function updateFieldProp(prop, value) {
  updateField({ [prop]: value });
}

function toggleAdvancedOptions() {
  showAdvancedOptions.value = !showAdvancedOptions.value;
}

function clearField() {
  emit('clearField');
  EventBus.$emit('clearField');
}

function updateOption({ key, value }) {
  updateFieldProp('options', {
    ...field.value?.options,
    [key]: value,
  });

  // Field's is_public_form has impact on the is_shown_on_new page
  // of the feild's section.
  if (key === 'is_public_form') {
    emit('update:section', props.section);
  }
}

function clipboardSystemReference() {
  const systemReference =
    field.value.system_reference && field.value.system_reference.length
      ? field.value.system_reference
      : systemReferenceDefault.value;

  if (navigator.clipboard) {
    navigator.clipboard.writeText(systemReference);
  } else {
    const textArea = document.createElement('textarea');
    textArea.value = systemReference;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  toastStore.success('Copied the reference to clipboard.');
}

onBeforeUnmount(() => {
  EventBus.$off('highlightFieldLabelInput');
});

onMounted(() => {
  EventBus.$on('highlightFieldLabelInput', () => {
    fieldLabelInput.value?.focus();
    fieldLabelInput.value?.select();
  });
});
</script>

<script lang="ts">
export default {
  components: options.components,
};
</script>
