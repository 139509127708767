import {
  checkIsBufferLayerModel,
  checkIsPlainFolderLayerModel,
  checkIsSampleGroup,
  checkIsTempLayerModel,
  throwError,
} from '@maps/lib/olbm';
import { BasemapId } from '@maps/lib/olbm/layer/basemap/types';
import { checkIsCalloutLayerModel } from '@maps/lib/olbm/layer/call-out/utils';
import { LayerUsageByType } from '@maps/lib/olbm/layer/constants';
import type { Integration } from '@maps/lib/olbm/layer/service/integration';
import { IntegrationId } from '@maps/lib/olbm/layer/service/integration';
import { BufferLayerModel } from '@maps/lib/olbm/layer/shape/types';
import { TempLayerModel } from '@maps/lib/olbm/layer/types';
import type { FindLayerModelsPredicate } from '@maps/lib/olbm/layer/utils';
import {
  findLayerModels as _findLayerModels,
  updateLayerModel as _updateLayerModel,
} from '@maps/lib/olbm/layer/utils';
import type {
  Id,
  LayerModel,
  Sample,
  SampleGroup,
  SubFolder,
} from '@maps/lib/olbm/types';
import {
  ErrorCode,
  Figure,
  Geojson,
  LayerType,
  Properties,
} from '@maps/lib/olbm/types';
import { produce, setAutoFreeze } from 'immer';
import _isEqual from 'lodash/isEqual';
import _omit from 'lodash/omit';
import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import { ref } from 'vue';
import useMapsApi from '../composables/useMapsApi';
import useFigureStore from './figure';
import useSampleStore from './sample';

const useLayerModelStore = defineStore('layerModel', () => {
  setAutoFreeze(false); // Matt to confirm, fixes immer reactivity errors. I wonder if immer

  const mapsApi = useMapsApi();
  const sampleStore = useSampleStore();
  const figureStore = useFigureStore();

  const layerModels: Ref<LayerModel[]> = ref([]);
  const subFolders: Ref<SubFolder[]> = ref([]);
  const integrations: Ref<Integration[]> = ref([]);

  const loadLayerModels = async (figureId: number) => {
    const { layerModels: _layerModels, lopSampleByLayerId } =
      await mapsApi.loadLayerModels(figureId);

    layerModels.value = _layerModels;
    sampleStore.addSamples(Object.values(lopSampleByLayerId));
  };

  const loadSubFolders = async () => {
    subFolders.value = await mapsApi.loadSubFolders();
  };

  const loadIntegrations = async () => {
    const ids: IntegrationId[] = [];

    const basemapApis = figureStore.getAvailableBasemapApis();
    if (basemapApis.some((basemapApi) => basemapApi.id === BasemapId.NEARMAP)) {
      ids.push(IntegrationId.NEARMAP);
    }
    if (
      basemapApis.some((basemapApi) => basemapApi.id === BasemapId.METROMAP)
    ) {
      ids.push(IntegrationId.METROMAP);
    }

    integrations.value = await mapsApi.loadIntegrations(ids);
  };

  const findLayerModels = (predicate: FindLayerModelsPredicate) => {
    return _findLayerModels(layerModels.value, predicate);
  };

  const findLayerModelById = (id: Id): LayerModel | undefined => {
    const layerModels = findLayerModels((layerModel) => layerModel.id === id);
    return layerModels[0];
  };

  const findLayerModelsByType = (type: LayerType) => {
    return findLayerModels(
      (layerModel) => layerModel.geojson.properties.type === type
    );
  };

  const getStylableLayerModels = () => {
    const rectangles = findLayerModelsByType(LayerType.RECTANGLE);
    const circles = findLayerModelsByType(LayerType.CIRCLE);
    const polygons = findLayerModelsByType(LayerType.POLYGON);
    const polylines = findLayerModelsByType(LayerType.POLYLINE);
    const arrows = findLayerModelsByType(LayerType.ARROW);
    const hedges = findLayerModelsByType(LayerType.HEDGE);
    return [
      ...rectangles,
      ...circles,
      ...polygons,
      ...polylines,
      ...arrows,
      ...hedges,
    ];
  };

  const checkIsLayerModelHidden = (id: Id) => {
    const layerModel = findLayerModelById(id);

    if (!layerModel) {
      throwError(ErrorCode.LayerModelNotFound, id);
    }

    return !layerModel.visible
      ? true
      : checkIsBufferLayerModel(layerModel)
      ? layerModel.geojson.properties.folderId
        ? checkIsLayerModelHidden(layerModel.geojson.properties.folderId)
        : checkIsLayerModelHidden(
            layerModel.geojson.properties.boundLayerIds[0]
          )
      : layerModel.parent_id
      ? checkIsLayerModelHidden(layerModel.parent_id)
      : false;
  };

  const checkIsRenderableNonSpatialSampleGroup = (id: Id): boolean => {
    const model = findLayerModelById(id);
    return (
      !!model &&
      checkIsSampleGroup(model) &&
      !!model.geojson.properties.renderableAppLinkConfig
    );
  };

  const toRenderableLayerModels = (_layerModels: any[]): any[] => {
    const result: LayerModel[] = [];
    for (let i = 0; i < _layerModels.length; i++) {
      const layerModel = _layerModels[i];
      if (checkIsPlainFolderLayerModel(layerModel)) {
        result.push(...toRenderableLayerModels(layerModel.children));
      } else if (
        !checkIsRenderableNonSpatialSampleGroup(layerModel.id) &&
        (!checkIsCalloutLayerModel(layerModel) ||
          (!layerModel.geojson.properties.isBuiltin &&
            // Map callout is not supported.
            layerModel.geojson.properties.usage !==
              LayerUsageByType[LayerType.CALL_OUT].SHOW_MAP))
      ) {
        result.push(layerModel);
      }
    }
    return result;
  };

  const getAllSampleGroups = (): SampleGroup[] => {
    return findLayerModelsByType(LayerType.SAMPLE_GROUP) as SampleGroup[];
  };

  const getDefaultSampleGroup = (): SampleGroup => {
    return getAllSampleGroups().find((sampleGroup) => {
      return sampleGroup.geojson.properties.default;
    })! as SampleGroup;
  };

  const getSampleLayerModel = (sample: Sample): LayerModel | undefined => {
    const { project_figure_layer_id: layerModelId } = sample;

    return layerModelId
      ? findLayerModelById(layerModelId)
      : getDefaultSampleGroup();
  };

  // The newly created layer model should be put on the top.
  const addLayerModel = async (
    figure: Figure | undefined,
    layerModel: LayerModel,
    afterLayerId: number | null = null
  ) => {
    const { parent_id: parentId } = layerModel;
    if (parentId) {
      const parent = findLayerModelById(parentId);
      const newChildren = produce([...parent!.children], (draft) => {
        draft.unshift(layerModel);
      });
      updateLayerModel(parent!.id, { children: newChildren });
    } else {
      layerModels.value = produce([...layerModels.value], (draft) => {
        if (afterLayerId !== null) {
          const index = draft.findIndex((layer) => layer.id === afterLayerId);
          if (index !== -1) {
            draft.splice(index + 1, 0, layerModel);
          } else {
            console.error('Specified afterLayerId does not exist.');
            draft.unshift(layerModel);
          }
        } else {
          draft.unshift(layerModel);
        }
      });
    }

    if (
      figure &&
      !checkIsTempLayerModel(layerModel) &&
      typeof layerModel.id === 'number'
    ) {
      await mapsApi.updateLayerModelVisibility(
        figure.id,
        layerModel.id,
        layerModel.visible
      );
      await updateLayerOrdering();
    }
  };

  const updateLayerOrdering = async () => {
    const { project_id } = figureStore.getProject();
    const layersForOrdering = layerModels.value.map((layerModel) => {
      return {
        project_id,
        id: layerModel.id,
        isFolder: layerModel.geojson.properties.type === LayerType.FOLDER,
        children: layerModel.children.map((child) => ({
          project_id,
          id: child.id,
        })),
      };
    });
    await mapsApi.modifyLayerOrdering(layersForOrdering);
  };

  const removeLayerModel = (id: Id) => {
    const layerModel = findLayerModelById(id);

    if (!layerModel) {
      return;
    }

    const { parent_id: parentId } = layerModel!;
    if (parentId) {
      const parent = findLayerModelById(parentId);
      const newChildren = produce([...parent!.children], (draft) => {
        const index = draft.findIndex((child) => child.id === id);
        draft.splice(index, 1);
      });
      updateLayerModel(parent!.id, { children: newChildren });
    } else {
      layerModels.value = produce([...layerModels.value], (draft) => {
        const index = draft.findIndex((layerModel) => layerModel.id === id);
        draft.splice(index, 1);
      });
    }
  };

  const clearTempLayerModels = (): Id[] => {
    const result: Id[] = [];
    const tempLayerModels = findLayerModels((layerModel) =>
      checkIsTempLayerModel(layerModel)
    ) as TempLayerModel[];
    for (let i = 0; i < tempLayerModels.length; i++) {
      const tempLayerModel = tempLayerModels[i];
      result.push(tempLayerModel.id);
      if (checkIsSampleGroup(tempLayerModel)) {
        const samples = sampleStore.getScopedSamples(tempLayerModel);
        for (const s of samples) {
          sampleStore.removeSample(s.id);
        }
      }
      removeLayerModel(tempLayerModel.id);
    }
    return result;
  };

  const updateLayerModel = (layerModelId: Id, update: object) => {
    layerModels.value = _updateLayerModel(
      layerModels.value,
      layerModelId,
      update
    );
  };

  const updateGeojson = <T extends Properties>(
    sample: Sample,
    update: Geojson<T>
  ): void => {
    const model = getSampleLayerModel(sample)!;
    let { properties } = update;
    properties = _omit(properties, ['layerId']);
    updateLayerModel(model.id, { geojson: { ...update, properties } });
  };

  const toggleLayerModelVisibility = async (figureId: Id, layerModelId: Id) => {
    const model = findLayerModelById(layerModelId);

    if (!model) {
      throwError(ErrorCode.LayerModelNotFound, layerModelId);
    }

    const isVisible = !model.visible;
    await mapsApi.updateLayerModelVisibility(figureId, layerModelId, isVisible);
    updateLayerModel(model.id, { visible: isVisible });
  };

  const toggleSubFolderVisibility = async (
    figureId: Id,
    layerModelId: Id,
    subFolder: string
  ) => {
    const model = findLayerModelById(layerModelId);

    if (!model) {
      throwError(ErrorCode.LayerModelNotFound, layerModelId);
    }

    const sampleGroup = model as SampleGroup;
    const index = sampleGroup.hidden_sub_folders.indexOf(subFolder);
    const nextHiddenSubFolders =
      index === -1
        ? [...sampleGroup.hidden_sub_folders, subFolder]
        : sampleGroup.hidden_sub_folders.toSpliced(index, 1);
    await mapsApi.updateSubFolderVisibility(
      figureId,
      layerModelId,
      nextHiddenSubFolders
    );
    updateLayerModel(model.id, { hidden_sub_folders: nextHiddenSubFolders });
  };

  const getBufferLayerModel = (
    model: LayerModel
  ): BufferLayerModel | undefined => {
    if (checkIsPlainFolderLayerModel(model)) {
      const folderId = model.id;
      const [result] = findLayerModels(
        (model) =>
          checkIsBufferLayerModel(model) &&
          model.geojson.properties.folderId === folderId
      );
      return result as BufferLayerModel;
    } else {
      const boundLayerId = model.id;
      const [result] = findLayerModels(
        (model) =>
          checkIsBufferLayerModel(model) &&
          !model.geojson.properties.folderId &&
          _isEqual(model.geojson.properties.boundLayerIds, [boundLayerId])
      );
      return result as BufferLayerModel;
    }
  };

  return {
    layerModels,
    subFolders,
    integrations,
    loadLayerModels,
    findLayerModels,
    findLayerModelById,
    findLayerModelsByType,
    getAllSampleGroups,
    getDefaultSampleGroup,
    getSampleLayerModel,
    addLayerModel,
    removeLayerModel,
    clearTempLayerModels,
    updateLayerModel,
    updateGeojson,
    checkIsLayerModelHidden,
    checkIsRenderableNonSpatialSampleGroup,
    toRenderableLayerModels,
    toggleLayerModelVisibility,
    toggleSubFolderVisibility,
    getBufferLayerModel,
    loadSubFolders,
    getStylableLayerModels,
    loadIntegrations,
  };
});

export default useLayerModelStore;
