<script setup lang="ts">
import { ref } from 'vue';

defineProps<{
  title: string;
}>();

const isOpen = ref(true);
</script>

<template>
  <div class="position-relative">
    <div
      class="section-header p-3 d-flex align-items-center justify-content-between bg-light dropdown-toggle"
      :class="{
        toggled: isOpen,
        'mb-3': isOpen,
      }"
      @click="isOpen = !isOpen"
    >
      <h6 class="mb-0">{{ title }}</h6>
    </div>

    <!-- Use v-show to prevent vue-draggable error in slot. -->
    <div v-show="isOpen">
      <slot />
    </div>
  </div>
</template>
