<script lang="ts" setup>
import UpgradeIndicator from './UpgradeIndicator.vue';
import { FeatureKey, hasAccess } from '../feature-manager';
import { computed, onUnmounted, watch } from 'vue';
import { useSubscriptionStore } from '../store/subscription';

const props = defineProps<{
  featureKey: FeatureKey;
  featureName: string;
  disableCheck?: boolean;
  opacity?: number;
  hideUpgradeIndicator?: boolean;
  isDisplayContents?: boolean;
}>();

const subscriptionStore = useSubscriptionStore();
const hasFeatureAccess = computed(() => {
  return hasAccess(props.featureKey) || props.disableCheck === true;
});

watch(
  () => hasFeatureAccess.value,
  () => {
    if (!hasFeatureAccess.value) {
      subscriptionStore.addToFeatureInPreview(props.featureKey);
    } else {
      subscriptionStore.removeFromFeatureInPreview(props.featureKey);
    }
  },
  { immediate: true }
);

onUnmounted(() => {
  subscriptionStore.removeFromFeatureInPreview(props.featureKey);
});
</script>
<template>
  <UpgradeIndicator
    v-if="!hasFeatureAccess && !hideUpgradeIndicator"
    :featureName="featureName"
  />
  <div
    :class="{
      disabled: !hasFeatureAccess,
      'd-contents': isDisplayContents,
      'd-flex flex-column flex-fill': !isDisplayContents,
    }"
    :style="{
      opacity: hasFeatureAccess ? 1 : opacity ?? 0.5,
      pointerEvents: hasFeatureAccess ? 'auto' : 'none',
    }"
  >
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.d-contents {
  display: contents;
}
</style>
