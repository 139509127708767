<script setup lang="ts">
import { ref } from 'vue';

defineProps<{
  isActive?: boolean;
  hasPadding?: boolean;
}>();

const showDropdown = ref(false);
</script>
<template>
  <li v-click-outside="() => (showDropdown = false)" class="nav-item dropdown">
    <a
      class="btn btn-header dropdown-toggle"
      href="#"
      :class="{
        active: isActive,
      }"
      @click.prevent="showDropdown = !showDropdown"
    >
      <slot name="triggerBtn" />
    </a>
    <div
      class="dropdown-menu mt-2"
      :class="[
        {
          show: showDropdown,
          'has-padding': hasPadding,
        },
      ]"
      style="right: 0; max-height: calc(100vh - 5rem); overflow-y: auto"
    >
      <slot name="dropdownItems" />
    </div>
  </li>
</template>
<style scoped lang="scss">
:deep(.navbar-nav .nav-link) {
  display: flex;
  align-items: center;
}

:deep(.dropdown-menu.has-padding) {
  padding: 0.5rem !important;
}

:deep(.dropdown-item i) {
  margin-right: 8px;
  opacity: 0.5 !important;
  font-size: 16px !important;
}

:deep(.dropdown-item:first-child) {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

:deep(.dropdown-item:last-child) {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

:deep(.nav-item .btn) {
  display: flex;
  align-items: center;
  height: 100%;
}

:deep(.nav-item .btn.active) {
  background: #fcfefd;
}

:deep(.dropdown-item) {
  padding: 8px 10px;
  margin-bottom: 3px;
}

:deep(.user-information) {
  padding: 0.75em;
  border-radius: 1rem;
  border: 1px solid #f4f4f4;
  margin-bottom: 0.75em;
}

@media all and (max-width: 992px) {
  :deep(.btn-header) {
    display: none;
  }

  :deep(.nav-item) {
    margin-right: 0px;
  }

  :deep(.nav-item a) {
    padding: 1rem 1rem;
  }

  :deep(.nav-item:last-child) {
    border-bottom: none;
  }

  :deep(.nav-item .btn.dropdown-toggle) {
    display: none;
  }

  :deep(.navbar-nav) {
    width: 100%;
    overflow-y: auto;
  }

  :deep(.nav-item .btn.active) {
    background: none;
  }

  :deep(.dropdown-menu.has-padding) {
    padding: 8px 0px !important;
  }

  :deep(.dropdown-menu) {
    margin-top: 0px !important;
    background: none;
    display: block !important;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #f4f4f4 !important;
    width: 100%;
  }

  :deep(.dropdown-item) {
    font-weight: 500;
    color: #212121;
    padding: 1em !important;
  }

  :deep(.dropdown-item i) {
    font-size: 15px;
  }

  :deep(.user-information) {
    margin-bottom: 1em;
  }
}
</style>
